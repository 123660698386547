import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { PrintComponent } from "./print/print.component";
import { ScanComponent } from "./scan/scan.component";
import { LayoutComponent } from "./layout.component";
import { MaterialsListComponent } from "./materials-list/materials-list.component";
import { RetrospectStatementComponent } from "./retrospect-statement/retrospect-statement.component";

const routes: Routes = [
  {
    path: "",
    component: LayoutComponent,
    children: [
      { path: "scan", component: ScanComponent },
      { path: "print", component: PrintComponent },
      { path: "materials", component: MaterialsListComponent },
      { path: "retrospect", component: RetrospectStatementComponent },
      { path: "**", redirectTo: "print" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LayoutRoutingModule {}
