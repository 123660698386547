import {
  Component,
  OnInit,
  Input,
  HostListener,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  SimpleChanges,
} from "@angular/core";

interface Selection {
  field: string;
  title: string;
  type: string;
  data: DropdownSelection[] | null;
}

interface BoolSelection {
  text: string;
  value: number;
}

interface DropdownSelection {
  text: string;
  value: any;
  selected: boolean;
}

interface Filters {
  title: String;
  field: String;
  condition: String;
  value: String;
  max: String | null;
  show: boolean;
}

interface EmitFilters {
  field: String;
  condition: String;
  value: String | number;
  max: String | number | null;
}

enum KEY_CODE {
  TOP_ARROW = "ArrowUp",
  DOWN_ARROW = "ArrowDown",
  ENTER_ARROW = "Enter",
  ESCAPE = "Escape",
}

@Component({
  selector: "app-table-filter",
  templateUrl: "./table-filter.component.html",
  styleUrls: ["./table-filter.component.less"],
})
export class TableFilterComponent implements OnInit, AfterViewInit {
  @Input() headers: Array<{
    field: string;
    title: string;
    type: string;
    data: DropdownSelection[] | null;
  }> = [];
  @Input() rangeStep: number = 1;
  @Input() rangeStart: number = 0;
  @Input() rangeEnd: number;
  @Input() showHelpTip: boolean = false;
  @Input() helpTip: String = "";
  @Input() showSearchBtn: boolean = false;
  @Input() searchDisabled: boolean = false;
  @Input() initSelection: Array<{
    value: any;
    type: string;
    field: string;
    title: string;
  }>;
  @Input() open: boolean = false;
  @Input() title: string = "筛选";
  @Input() initTableFilters: Filters[] = [];
  @Output() handleFilterChange = new EventEmitter<EmitFilters[]>();
  @Output() handleFilterSelect = new EventEmitter<string>();

  inputValue: string = "";
  selections: Selection[] = [];
  selected: boolean = false;
  selectionText: string = "";
  placeholderText: string = "请输入关键字识别搜索";
  openDatepicker: boolean = false;
  openSelectionDropdown: boolean = false;
  initDatePicker: boolean = true;
  selectedType: string = "string";
  selectedKey: String;
  dateRange = [];
  selectedBoolValue: string | number;
  selectionDataList: DropdownSelection[] = [];
  rangePickerstyle = {
    position: "absolute",
    left: 0,
    top: "-1px",
    zIndex: 1,
  };
  boolSelectionMap: BoolSelection[] = [
    {
      text: "是",
      value: 1,
    },
    {
      text: "否",
      value: 0,
    },
  ];
  tableFilters: Filters[] = [];
  tableEmitFilters: EmitFilters[] = [];
  rangeStartValue: number;
  rangeEndValue: number;
  rangeStartMaxValue: number;
  rangeEndMinValue: number;
  rangeAutoFocus: boolean = true;
  rangeInputFocused: boolean = false;
  booleanActiveSelection: BoolSelection;
  dropdownActiveSelection: DropdownSelection;
  showFilters: boolean = true;
  focused: boolean;
  infinity = 99999999;
  dropdownActive = false;

  @ViewChild("inputView", { static: false }) inputView: ElementRef;
  @ViewChild("inputNumberView", { static: false }) inputNumberView: ElementRef;

  constructor() {}

  @HostListener("window:keyup", ["$event"])
  KeyboardEvent(event: KeyboardEvent) {
    if (
      this.selectedType === "boolean" &&
      this.openSelectionDropdown === true
    ) {
      if (event.code === KEY_CODE.DOWN_ARROW) {
        for (let i = 0; i < this.boolSelectionMap.length; i++) {
          if (this.booleanActiveSelection === this.boolSelectionMap[i]) {
            if (i >= this.boolSelectionMap.length - 1) {
              this.booleanActiveSelection = this.boolSelectionMap[0];
            } else {
              this.booleanActiveSelection = this.boolSelectionMap[i + 1];
            }
            break;
          }
        }
      }
      if (event.code === KEY_CODE.TOP_ARROW) {
        for (let i = 0; i < this.boolSelectionMap.length; i++) {
          if (this.booleanActiveSelection === this.boolSelectionMap[i]) {
            if (i === 0) {
              this.booleanActiveSelection =
                this.boolSelectionMap[this.boolSelectionMap.length - 1];
            } else {
              this.booleanActiveSelection = this.boolSelectionMap[i - 1];
            }
            break;
          }
        }
      }
      if (event.code === KEY_CODE.ENTER_ARROW) {
        this.handleSelectBoolChange(this.booleanActiveSelection);
      }
      if (event.code === KEY_CODE.ESCAPE) {
        this.openSelectionDropdown = false;
      }
    }

    if (this.selectedType === "select" && this.openSelectionDropdown === true) {
      if (event.code === KEY_CODE.DOWN_ARROW) {
        for (let i = 0; i < this.selectionDataList.length; i++) {
          if (this.dropdownActiveSelection === this.selectionDataList[i]) {
            if (i >= this.selectionDataList.length - 1) {
              this.dropdownActiveSelection = this.selectionDataList[0];
            } else {
              this.dropdownActiveSelection = this.selectionDataList[i + 1];
            }
            break;
          }
        }
      }
      if (event.code === KEY_CODE.TOP_ARROW) {
        for (let i = 0; i < this.selectionDataList.length; i++) {
          if (this.dropdownActiveSelection === this.selectionDataList[i]) {
            if (i === 0) {
              this.dropdownActiveSelection =
                this.selectionDataList[this.selectionDataList.length - 1];
            } else {
              this.dropdownActiveSelection = this.selectionDataList[i - 1];
            }
            break;
          }
        }
      }
      if (event.code === KEY_CODE.ENTER_ARROW) {
        this.handleSelectChange(this.dropdownActiveSelection);
      }
      if (event.code === KEY_CODE.ESCAPE) {
        this.openSelectionDropdown = false;
      }
    }
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.initTableFilters !== undefined) {
      // if (changes.initTableFilters.currentValue.length) {

      // }
      this.tableFilters = changes.initTableFilters.currentValue.map((item) => {
        return {
          ...item,
          show: true,
        };
      });
      this.tableEmitFilters = changes.initTableFilters.currentValue.map(
        (item) => {
          if (item.condition === "between") {
            return { ...item, value: item._value, max: item._max };
          }
          return item;
        }
      );
    }
    if (changes.initSelection !== undefined) {
      if (
        changes.initSelection.currentValue &&
        changes.initSelection.currentValue.length
      ) {
        this.selectedType = changes.initSelection.currentValue[0]["type"];
        this.selectedKey = changes.initSelection.currentValue[0]["field"];
        this.selectionText = changes.initSelection.currentValue[0].title;
        this.inputValue = changes.initSelection.currentValue[0].value;
        this.placeholderText = `输入关键字识别搜索`;
        if (this.selectedType === "date") this.initDatePicker = false;
        this.handleInputEnter();
      }
    }
  }

  init() {
    this.selectedType = this.headers[0]["type"];
    this.selectedKey = this.headers[0]["field"];
    this.selectionText = this.headers[0].title;
    this.placeholderText = `输入关键字识别搜索`;
    this.headers.forEach((item, index: number) => {
      let selection: Selection = {
        field: item.field,
        title: item.title,
        type: item.type,
        data: item.data,
      };
      this.selections.push(selection);
    });

    if (this.selectedType === "range") {
      this.rangeStartValue = Number(this.rangeStart);
      this.rangeEndValue =
        this.rangeEnd === undefined ? this.infinity : Number(this.rangeEnd);
      this.rangeStartMaxValue =
        this.rangeEnd === undefined ? this.infinity : Number(this.rangeEnd);
      this.rangeEndMinValue = Number(this.rangeStart) + Number(this.rangeStep);
    }
    if (this.selectedType === "boolean") {
      this.booleanActiveSelection = this.boolSelectionMap[0];
    }
    if (this.selectedType === "select") {
      if (!this.selectionDataList.length) {
        this.selectionDataList = this.headers[0]["data"];
      }
      this.dropdownActiveSelection = this.selectionDataList[0];
    }
  }

  ngOnInit() {
    this.rangeStartValue = Number(this.rangeStart);
    this.rangeEndValue =
      this.rangeEnd === undefined ? this.infinity : Number(this.rangeEnd);
    this.rangeStartMaxValue =
      this.rangeEnd === undefined ? this.infinity : Number(this.rangeEnd);
    this.rangeEndMinValue = Number(this.rangeStart) + Number(this.rangeStep);

    if (this.headers.length === 0) return;
    this.selectedType = this.headers[0]["type"];
    this.selectedKey = this.headers[0]["field"];
    this.selectionText = this.headers[0].title;
    this.placeholderText = `输入关键字识别搜索`;
    this.headers.forEach((item, index: number) => {
      let selection: Selection = {
        field: item.field,
        title: item.title,
        type: item.type,
        data: item.data,
      };
      this.selections.push(selection);
    });
  }

  handleItemSelect(selectedItem: Selection) {
    const that = this;
    this.selected = true;
    this.selectionText = selectedItem.title;
    this.selectedType = selectedItem.type;
    this.selectedKey = selectedItem.field;
    this.inputValue = "";
    this.openSelectionDropdown = false;
    if (this.handleFilterSelect !== null) {
      this.handleFilterSelect.emit(selectedItem.field);
    }
    if (
      selectedItem.type === "string" ||
      selectedItem.type === "int" ||
      selectedItem.type === "double"
    ) {
      this.placeholderText = `输入${this.selectionText}搜索`;
      setTimeout(() => {
        that.inputView.nativeElement.focus();
      }, 10);
    } else if (
      selectedItem.type === "boolean" ||
      selectedItem.type === "select" ||
      selectedItem.type === "date"
    ) {
      this.placeholderText = `选择${this.selectionText}项搜索`;
      if (selectedItem.type === "date") {
        this.openDatepicker = true;
        setTimeout(() => {
          that.inputView.nativeElement.onfocus = function () {
            that.openDatepicker = true;
          };
        }, 10);
      }
      if (selectedItem.type === "boolean") {
        this.booleanActiveSelection = this.boolSelectionMap[0];
        this.openSelectionDropdown = true;
        setTimeout(() => {
          that.inputView.nativeElement.onfocus = function () {
            that.openSelectionDropdown = true;
          };
        }, 10);
      }

      if (selectedItem.type === "select") {
        this.selectionDataList = [];
        selectedItem.data.forEach((item) => {
          this.selectionDataList.push(item);
        });

        this.dropdownActiveSelection = this.selectionDataList[0];
        this.openSelectionDropdown = true;
        setTimeout(() => {
          that.inputView.nativeElement.onfocus = function () {
            that.openSelectionDropdown = true;
          };
        }, 10);
      }
    } else if (selectedItem.type === "range") {
      this.placeholderText = `选择${this.selectionText}值搜索`;
      setTimeout(() => {
        that.inputView.nativeElement.onfocus = function () {
          that.openSelectionDropdown = true;
        };
        that.openSelectionDropdown = true;
        that.rangeStartValue = Number(that.rangeStart);
        that.rangeStartMaxValue = Number(
          that.rangeEnd === undefined ? that.infinity : that.rangeEnd
        );
        that.rangeEndMinValue =
          Number(that.rangeStart) + Number(that.rangeStep); // 2
        that.rangeEndValue = Number(
          that.rangeEnd === undefined ? that.rangeEndMinValue : that.rangeEnd
        );
      }, 10);
    }
  }

  handleInputEnter(boolValue?: BoolSelection | DropdownSelection) {
    if (this.selectedKey === undefined) {
      this.init();
    }
    let filters: Filters;
    let emitFilter: EmitFilters;
    let flag: boolean = false;

    if (this.selectedType === "date") {
      if (this.dateRange.length === 0) return;
      // 处理选择同一天（结束时间为当天的23:59:59）
      // 把开始时间改为当天的0:0:0
      let startTime = Date.parse(this.dateRange[0]);
      let endTime = Date.parse(this.dateRange[1]);

      if (startTime === endTime) {
        endTime = Number(
          Date.parse(
            new Date( // 当天23:59:59
              new Date(new Date(endTime).toLocaleDateString()).getTime() +
                24 * 60 * 60 * 1000 -
                1
            ).toString()
          )
        );
      }
      startTime = Number(
        Date.parse(
          new Date(
            new Date(new Date(startTime).toLocaleDateString()).getTime()
          ).toString()
        )
      );

      emitFilter = {
        field: this.selectedKey,
        condition: "between",
        max: endTime,
        value: startTime,
      };
      filters = {
        title: this.selectionText,
        field: this.selectedKey,
        condition: "between",
        max: `${new Date(Date.parse(this.dateRange[1])).getFullYear()}-${
          new Date(Date.parse(this.dateRange[1])).getMonth() + 1
        }-${new Date(Date.parse(this.dateRange[1])).getDate()}`,
        value: `${new Date(Date.parse(this.dateRange[0])).getFullYear()}-${
          new Date(Date.parse(this.dateRange[0])).getMonth() + 1
        }-${new Date(Date.parse(this.dateRange[0])).getDate()}`,
        show: true,
      };
    } else if (this.selectedType === "range") {
      filters = {
        title: this.selectionText,
        field: this.selectedKey,
        condition: "between",
        value: this.rangeStartValue.toString(),
        max: this.rangeEndValue.toString(),
        show: true,
      };
      emitFilter = {
        field: this.selectedKey,
        condition: "between",
        value: this.rangeStartValue.toString(),
        max: this.rangeEndValue.toString(),
      };
      this.openSelectionDropdown = false;
    } else {
      if (this.inputValue === "") return;
      filters = {
        title: this.selectionText,
        field: this.selectedKey,
        condition: "equal",
        value:
          boolValue !== undefined
            ? boolValue.text
            : this.inputValue.toString().trim(),
        max: null,
        show: this.inputValue === "" ? false : true,
      };
      emitFilter = {
        field: this.selectedKey,
        condition: "equal",
        value:
          boolValue !== undefined
            ? boolValue.value.toString()
            : this.inputValue.toString().trim(),
        max: null,
      };
    }
    for (let i = 0; i < this.tableFilters.length; i++) {
      if (this.tableFilters[i].field === filters.field) {
        flag = true;
        this.tableFilters[i] = filters;
        this.tableEmitFilters[i] = emitFilter;
        break;
      }
    }
    if (flag === false) {
      this.tableFilters.push(filters);
      this.tableEmitFilters.push(emitFilter);
    }
    if (this.tableFilters.length === 1 && this.tableFilters[0].show === false) {
      this.showFilters = false;
    } else {
      this.showFilters = true;
    }

    if (this.handleFilterChange !== null)
      this.handleFilterChange.emit(this.tableEmitFilters);
    if (
      this.selectedType === "date" ||
      this.selectedType === "range" ||
      this.selectedType === "boolean"
    )
      return;
    // this.inputView.nativeElement.focus();
    this.inputValue = "";
  }

  handleSelectBoolChange(selectedValue: BoolSelection) {
    const that = this;
    this.inputValue = selectedValue.text;

    this.booleanActiveSelection = selectedValue;

    this.handleInputEnter(selectedValue);
    setTimeout(() => {
      that.openSelectionDropdown = false;
      that.inputValue = "";
    }, 10);
  }

  handleSelectChange(selectedValue: DropdownSelection) {
    const that = this;
    this.inputValue = selectedValue.text;
    this.dropdownActiveSelection = selectedValue;
    this.handleInputEnter(selectedValue);
    setTimeout(() => {
      that.openSelectionDropdown = false;
      that.inputValue = "";
    }, 10);
  }

  onDateRangePickerChange(result: Date[]): void {
    let startTime: String;
    let endTime: String;
    this.dateRange = result;
    this.openDatepicker = false;
    this.initDatePicker = false;
    startTime = `${new Date(Date.parse(this.dateRange[0])).getFullYear()}-${
      new Date(Date.parse(this.dateRange[0])).getMonth() + 1
    }-${new Date(Date.parse(this.dateRange[0])).getDate()}`;
    endTime = `${new Date(Date.parse(this.dateRange[1])).getFullYear()}-${
      new Date(Date.parse(this.dateRange[1])).getMonth() + 1
    }-${new Date(Date.parse(this.dateRange[1])).getDate()}`;
    this.inputValue = `${startTime} ~ ${endTime}`;
    this.handleInputEnter();
  }

  onInputChange(newValue: string) {
    if (this.selectedType === "string") {
      this.inputValue = newValue.trim();
      return;
    }
    if (
      this.selectedType === "boolean" ||
      this.selectedType === "select" ||
      this.selectedType === "range"
    ) {
      this.openSelectionDropdown = false;
      return;
    }

    let reg: RegExp;
    if (this.selectedType === "double") reg = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/;
    if (this.selectedType === "int") reg = /^(0|[1-9][0-9]*)$/;
    if ((!isNaN(+newValue) && reg.test(newValue)) || newValue === "") {
      this.inputValue = newValue;
    }
    this.inputView.nativeElement.value = this.inputValue;
  }

  handleRangeStartChange(value: number) {
    if (value > this.rangeEndMinValue) {
      this.rangeEndMinValue = value;
      if (value > this.rangeEndValue) {
        this.rangeEndValue = value;
      }
    } else {
      this.rangeEndMinValue = value;
    }
  }

  handleTagClose(value: Filters) {
    for (let i = 0; i < this.tableFilters.length; i++) {
      if (this.tableFilters[i].field === value.field) {
        this.tableFilters.splice(i, 1);
        this.tableEmitFilters.splice(i, 1);
        break;
      }
    }
    if (this.handleFilterChange !== null)
      this.handleFilterChange.emit(this.tableEmitFilters);
  }

  handleClearFilter() {
    this.tableFilters.splice(0);
    this.tableEmitFilters.splice(0);
    if (this.handleFilterChange !== null)
      this.handleFilterChange.emit(this.tableEmitFilters);
  }

  handleSearch() {
    if (this.selectedType === "boolean") {
      this.handleInputEnter(this.booleanActiveSelection);
      return;
    }
    this.handleInputEnter();
  }
  // 按esc键关闭日历后的操作
  handleDatePickerOpenChange(value: boolean) {
    if (value === false) {
      this.openDatepicker = false;
      this.initDatePicker = false;
    }
  }

  onInputFocus() {
    const that = this;
    this.focused = true;
    if (this.selectedType === "select") {
      if (!this.selectionDataList.length) {
        this.selectionDataList = this.headers[0]["data"];
      }
      this.dropdownActiveSelection = this.selectionDataList[0];
      this.openSelectionDropdown = true;
      setTimeout(() => {
        this.inputView.nativeElement.onfocus = function () {
          this.openSelectionDropdown = true;
        };
      }, 10);
    } else if (this.selectedType === "date") {
      this.openDatepicker = true;
      setTimeout(() => {
        that.inputView.nativeElement.onfocus = function () {
          that.openDatepicker = true;
        };
      }, 10);
    }
  }

  onInputBlur() {
    this.focused = false;
  }

  dropdownVisibleChange(visible: boolean) {
    if (!visible) {
      if (this.selectedType === "date") {
        this.openDatepicker = false;
      }
    }
  }
}
