import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MaterialAddComponent } from "./pages/layout/materials-list/material-add/material-add.component";
import { MaterialDetailsComponent } from "./pages/layout/materials-list/material-details/material-details.component";
import { RetrospectDetailsComponent } from "./pages/layout/retrospect-statement/retrospect-details/retrospect-details.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "scanner",
    pathMatch: "full",
  },
  {
    path: "scanner",
    loadChildren: () =>
      import("./pages/layout/layout-routing.module").then(
        (m) => m.LayoutRoutingModule
      ),
  },
  {
    path: "print/:id",
    loadChildren: () =>
      import(
        "./pages/layout/print/scanner-print-detail/scanner-print-detail.module"
      ).then((m) => m.ScannerPrintDetailModule),
  },
  {
    path: "scan/:id",
    loadChildren: () =>
      import(
        "./pages/layout/scan/scanner-scan-detail/scanner-scan-detail.module"
      ).then((m) => m.ScannerScanDetailModule),
  },
  {
    path: "material/:id",
    component: MaterialDetailsComponent,
  },
  {
    path: "materials/addend",
    component: MaterialAddComponent,
  },
  {
    path: "retrospect/:id",
    component: RetrospectDetailsComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
