<div class="wrap">
  <div class="main">
    <div class="content">
      <div class="nav">
        <div class="home">
          <a [routerLink]="['/scanner/print']">应用首页</a>
        </div>
        <div class="divider">/</div>
        <div class="index">已打印</div>
      </div>
      <div class="content-container">
        <div class="content-table">
          <div class="wrapper-box">
            <nz-table
              [nzShowPagination]="false"
              [nzBordered]="true"
              [nzLoading]="loading"
              [nzScroll]="{ x: tableWidth }"
              [nzData]="data"
              nzSize="small"
            >
              <thead>
                <tr class="table-header-tr">
                  <ng-container *ngFor="let header of headers; let j = index">
                    <th
                      *ngIf="j === 0"
                      nzLeft="0px"
                      [nzWidth]="header['len']"
                      style="
                        font-weight: 400;
                        padding: 6px;
                        color: rgba(0, 0, 0, 0.45);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ header["label"] }}
                    </th>
                    <th
                      *ngIf="j > 0"
                      [nzWidth]="header['len']"
                      style="
                        font-weight: 400;
                        padding: 6px;
                        color: rgba(0, 0, 0, 0.45);
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                      "
                    >
                      {{ header["label"] }}
                    </th>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-template ngFor let-data let-index="index" [ngForOf]="data">
                  <tr class="table-tbody-tr">
                    <ng-container *ngFor="let header of headers; let i = index">
                      <td
                        *ngIf="i === 0"
                        nzLeft="0px"
                        [ngStyle]="{
                          position: 'relative',
                          borderBottom:
                            index === data.length - 1
                              ? '0'
                              : '1px solid #e5e5e5'
                        }"
                      >
                        <div>{{ data[header["id"]] }}</div>
                      </td>
                      <td
                        *ngIf="i > 0"
                        [ngStyle]="{
                          borderBottom:
                            index === data.length - 1
                              ? '0'
                              : '1px solid #e5e5e5'
                        }"
                      >
                        {{ data[header["id"]] }}
                      </td>
                    </ng-container>
                  </tr>
                </ng-template>
              </tbody>
            </nz-table>
            <div style="height: 20px"></div>
            <app-table-page
              [total]="total"
              [pageSizeOptions]="pageSizeOptions"
              [current]="page"
              [pageSize]="size"
              (handlePageIndexChange)="handlePageIndexChange($event)"
              (handlePageSizeChange)="handlePageSizeChange($event)"
            >
            </app-table-page>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
