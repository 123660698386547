import { NgModule, InjectionToken } from '@angular/core';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ResponseInterceptor } from '../services/http-interceptors/response-interceptor';

import { PrintService } from 'src/app/services/print/print.service';
import { ScanService } from 'src/app/services/scan/scan.service';

export const API_CONFIG = new InjectionToken('ApiConfigToken');

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptor,
      multi: true,
    },
    PrintService,
    ScanService,
  ],
})
export class ServicesModule {}
