<nz-modal
  [(nzVisible)]="isVisible"
  nzTitle="应用设置"
  (nzOnOk)="handleOk()"
  nzWidth="800px"
  [nzCancelText]="null"
  (nzOnCancel)="handleCancel()"
  [nzBodyStyle]="{ padding: '0px' }"
>
  <nz-layout>
    <nz-tabset
      nzTabPosition="left"
      [nzType]="'line'"
      [nzTabBarGutter]="0"
      style="background: #fff; height: 500px"
    >
      <nz-tab nzTitle="权限设置">
        <nz-layout class="layoutWhite">
          <div style="font-size: 16px">密码放行设置</div>
          <div style="margin: 15px 0px; color: #a1a1a1">
            采集器手工选择总成零件及扫描不满足要求时，可输入放行密码继续作业。
          </div>
          <div style="width: 210px">
            <nz-input-group [nzSuffix]="suffixTemplate">
              <form>
                <input
                  [type]="passwordVisible ? 'text' : 'password'"
                  nz-input
                  name="myform"
                  placeholder="input password"
                  [(ngModel)]="PassWord"
                  [disabled]="passworddisable"
                  autocomplete="new-password"
                />
              </form>
            </nz-input-group>
            <ng-template #suffixTemplate>
              <i
                style="cursor: pointer"
                nz-icon
                [nzType]="passwordVisible ? 'eye' : 'eye-invisible'"
                (click)="passwordVisible = !passwordVisible"
              ></i>
            </ng-template>
          </div>
        </nz-layout>
      </nz-tab>

      <nz-tab nzTitle="" [nzDisabled]="true"> </nz-tab>
    </nz-tabset>
  </nz-layout>
</nz-modal>
