import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { NgZorroAntdModule } from "ng-zorro-antd";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WalkthroughModule } from "ngx-walkthrough";
import { TablePageComponent } from "src/app/components/table-page/table-page.component";
import { TableFilterComponent } from "src/app/components/table-filter/table-filter.component";
import { TableComponent } from "../components/table/table.component";
import { EmptyComponent } from "../components/empty/empty.component";

@NgModule({
  exports: [
    TablePageComponent,
    TableComponent,
    TableFilterComponent,
    NgZorroAntdModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
  ],
  imports: [
    CommonModule,
    NgZorroAntdModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    WalkthroughModule,
  ],
  declarations: [
    TablePageComponent,
    TableComponent,
    TableFilterComponent,
    EmptyComponent,
  ],
})
export class AppCommonModule {}
