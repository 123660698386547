import { Component, OnInit } from "@angular/core";
import { PrintService } from "src/app/services/print/print.service";
import { ScanService } from "src/app/services/scan/scan.service";
import {
  getAmonthFromNow,
  getAweekFromNow,
  getMonthDayOneTime,
  getWeekMondayTime,
} from "src/app/utils/moment";
import { dateFormat } from "src/app/utils/timeformat";

interface FilterHeader {
  field: string;
  title: string;
  type: String;
  searchable: boolean;
  data?: Array<{ value: string; text: string; selected: boolean }> | null;
  preRender?: {
    precision?: number;
    format?: string;
  };
  len?: string;
}

@Component({
  selector: "app-scan",
  templateUrl: "./scan.component.html",
  styleUrls: ["./scan.component.less"],
})
export class ScanComponent implements OnInit {
  constructor(private printServ: PrintService, private scanServ: ScanService) {}

  ngOnInit() {
    this.scanMoveTabTrack(0);
    this.moveTabTrack(0);
  }

  pageSizeOptions = [10, 15, 20, 30, 40, 50];
  dateFormatFunc = dateFormat;
  scanFilterOrderHeaders: FilterHeader[] = [
    {
      field: "createUser",
      title: "扫描人",
      type: "string",
      searchable: true,
    },
    {
      field: "createTime",
      title: "扫描时间",
      type: "date",
      searchable: true,
    },
  ];

  scanDateRangeDisabled = false;
  scanTabTrackIndex = 0;
  _date = Date.parse(
    new Date(new Date(new Date().toLocaleDateString()).getTime()).toString()
  );
  _edate = Date.parse(
    new Date( // 当天23:59
      new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    ).toString()
  );
  scanListPostParams = {
    page: 1,
    size: 10,
    createTimeStart: this._date,
    createTimeEnd: this._edate,
    createUser: null,
  };
  scanInitTableFilters = [];
  _scanInitTableFilters = [];

  //列表加载
  scanListLoading = false;
  instanceId = window.localStorage.getItem("ins");
  scanListMap = {
    total: 0,
  };
  scanListData: Array<{
    id: number;
    createTime: number;
    createUser: string;
  }> = [];

  // 扫描时间自定义筛选
  scanMoveTabTrack(index: number, data?: any) {
    if (this.scanDateRangeDisabled) return;
    this.scanTabTrackIndex = index;
    if (index === 0) {
      this.scanListPostParams = {
        ...this.scanListPostParams,
        page: 1,
        createTimeStart: this._date,
        createTimeEnd: this._edate,
      };
      if (
        this.scanInitTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.scanInitTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", this._date),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };
        this._scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: this._date,
          max: this._edate,
        };
        this.scanInitTableFilters = [...this.scanInitTableFilters];
      } else {
        this.scanInitTableFilters = [
          ...this.scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", this._date),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._scanInitTableFilters = [
          ...this._scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: this._date,
            max: this._edate,
          },
        ];
      }
    }
    if (index === 1) {
      this.scanListPostParams = {
        ...this.scanListPostParams,
        page: 1,
        createTimeStart: getWeekMondayTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.scanInitTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.scanInitTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", getAweekFromNow()),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };
        this._scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: getAweekFromNow(),
          max: this._edate,
        };
        this.scanInitTableFilters = [...this.scanInitTableFilters];
      } else {
        this.scanInitTableFilters = [
          ...this.scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", getAweekFromNow()),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._scanInitTableFilters = [
          ...this._scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: getAweekFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 2) {
      this.scanListPostParams = {
        ...this.scanListPostParams,
        page: 1,
        createTimeStart: getMonthDayOneTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.scanInitTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.scanInitTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", getAmonthFromNow()),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };
        this._scanInitTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: getAmonthFromNow(),
          max: this._edate,
        };
        this.scanInitTableFilters = [...this.scanInitTableFilters];
      } else {
        this.scanInitTableFilters = [
          ...this.scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", getAmonthFromNow()),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._scanInitTableFilters = [
          ...this._scanInitTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: getAmonthFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 3) {
      this.scanListPostParams = {
        ...this.scanListPostParams,
        page: 1,
        createTimeStart: null,
        createTimeEnd: null,
      };
      this.scanInitTableFilters = [];
      this._scanInitTableFilters = [];
      if (data !== undefined) {
        for (let i of data) {
          if (i["condition"] === "equal") {
            this.scanInitTableFilters.push({
              condition: "equal",
              field: i["field"],
              value: i["value"],
              title: i["field"] === "createUser" ? "打印人" : "标签数量",
            });
            this._scanInitTableFilters.push({
              condition: "equal",
              field: i["field"],
              value: i["value"],
              title: i["field"] === "createUser" ? "打印人" : "标签数量",
            });
          }
          if (i["condition"] === "between") {
            this.scanInitTableFilters.push({
              condition: "between",
              field: i["field"],
              value: i["value"],
              max: i["max"],
              title: "标签数量",
            });
            this._scanInitTableFilters.push({
              condition: "between",
              field: i["field"],
              value: i["value"],
              max: i["max"],
              title: "标签数量",
            });
          }
        }
      }
    }
    this.getScanList();
  }

  dateRangeDisabled = false;
  tabTrackIndex = 0;
  printListPostParams = {
    page: 1,
    size: 10,
    createTimeStart: this._date,
    createTimeEnd: this._edate,
    createUser: null,
    totalStart: null,
    totalEnd: null,
  };
  initTableFilters = [];
  _initTableFilters = [];

  // 打印时间自定义筛选
  moveTabTrack(index: number, data?: any) {
    if (this.dateRangeDisabled) return;
    this.tabTrackIndex = index;
    if (index === 0) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: this._date,
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", this._date),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };
        this._initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: this._date,
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", this._date),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: this._date,
            max: this._edate,
          },
        ];
      }
    }
    if (index === 1) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: getWeekMondayTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", getAweekFromNow()),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };

        this._initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: getAweekFromNow(),
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", getAweekFromNow()),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: getAweekFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 2) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: getMonthDayOneTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item["field"] === "createTime")
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item["field"] === "createTime"
        );
        this.initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: dateFormat("yyyy-MM-dd", getAmonthFromNow()),
          max: dateFormat("yyyy-MM-dd", this._edate),
        };
        this._initTableFilters[timeInex] = {
          title: "打印时间",
          field: "createTime",
          condition: "between",
          value: getAmonthFromNow(),
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: dateFormat("yyyy-MM-dd", getAmonthFromNow()),
            max: dateFormat("yyyy-MM-dd", this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: "打印时间",
            field: "createTime",
            condition: "between",
            value: getAmonthFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 3) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: null,
        createTimeEnd: null,
      };
      this.initTableFilters = [];
      this._initTableFilters = [];
      if (data !== undefined) {
        for (let i of data) {
          if (i["condition"] === "equal") {
            this.initTableFilters.push({
              condition: "equal",
              field: i["field"],
              value: i["value"],
              title: i["field"] === "createUser" ? "打印人" : "标签数量",
            });
            this._initTableFilters.push({
              condition: "equal",
              field: i["field"],
              value: i["value"],
              title: i["field"] === "createUser" ? "打印人" : "标签数量",
            });
          }
          if (i["condition"] === "between") {
            this.initTableFilters.push({
              condition: "between",
              field: i["field"],
              value: i["value"],
              max: i["max"],
              title: "标签数量",
            });
            this._initTableFilters.push({
              condition: "between",
              field: i["field"],
              value: i["value"],
              max: i["max"],
              title: "标签数量",
            });
          }
        }
      }
    }
    this.getPrintList();
  }

  printListLoading = false;
  printListMap = {
    total: 0,
  };
  printListData: Array<{
    id: number;
    createTime: number;
    createUser: string;
    total: number;
  }> = [];
  // 打印列表
  getPrintList() {
    this.printListLoading = true;
    this.printServ
      .printListGet(this.instanceId, this.printListPostParams)
      .subscribe(
        (data) => {
          this.printListLoading = false;
          const { list, total } = data;
          this.printListMap.total = total;
          this.printListData = list;
        },
        (err) => {
          this.printListLoading = false;
        }
      );
  }

  // 扫描筛选
  scanListSearch(value: Array<{ [key: string]: string }>) {
    this.scanListPostParams = {
      ...this.scanListPostParams,
      page: 1,
      createTimeStart: null,
      createTimeEnd: null,
      createUser: null,
    };
    let flag = false;
    for (let i of value) {
      if (i.field === "createUser") {
        this.scanListPostParams.createUser = i.value;
      }
      if (i.field === "createTime") {
        if (this.scanInitTableFilters.length) {
          if (
            this.scanInitTableFilters.some(
              (item) => item["field"] === "createTime"
            )
          ) {
            const timeIndex = this.scanInitTableFilters.findIndex(
              (item) => item["field"] === "createTime"
            );
            this.scanListPostParams.createTimeStart =
              this._scanInitTableFilters[timeIndex]["value"];
            this.scanListPostParams.createTimeEnd =
              this._scanInitTableFilters[timeIndex]["max"];
          } else {
            this.scanListPostParams.createTimeStart = Number(i.value);
            this.scanListPostParams.createTimeEnd = Number(i.max);
          }
        } else {
          this.scanListPostParams.createTimeStart = Number(i.value);
          this.scanListPostParams.createTimeEnd = Number(i.max);
        }
        flag = true;
      }
    }
    this.scanDateRangeDisabled = flag;
    if (value.length && !flag) {
      this.scanMoveTabTrack(3, value);
    } else if (!value.length || !flag) {
      this.scanMoveTabTrack(3);
    } else {
      this.getScanList();
    }
  }

  // 扫描列表
  getScanList() {
    this.scanListLoading = true;
    this.scanServ
      .scanListGet(this.instanceId, this.scanListPostParams)
      .subscribe(
        (data) => {
          this.scanListLoading = false;
          const { list, total } = data;
          this.scanListMap.total = total;
          this.scanListData = list;
        },
        (err) => {
          this.scanListLoading = false;
        }
      );
  }

  // 处理扫描列表页索引更改
  handleScanListPageIndexChange(index: number) {
    this.scanListPostParams = {
      ...this.scanListPostParams,
      page: index,
    };
    this.getScanList();
  }

  // 处理扫描列表页大小变化
  handleScanListPageSizeChange(size: number) {
    this.scanListPostParams = {
      ...this.scanListPostParams,
      size: size,
    };
    this.getScanList();
  }
}
