<div class="page-wrapper">
  <div class="tb-filter-container">
    <div class="tb-date-filter-tab">
      <div class="track-content-container">
        <div
          class="track-content-box"
          [ngClass]="{ disabled: scanDateRangeDisabled }"
          (click)="scanMoveTabTrack(0)"
        >
          今天
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: scanDateRangeDisabled }"
          (click)="scanMoveTabTrack(1)"
        >
          本周
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: scanDateRangeDisabled }"
          (click)="scanMoveTabTrack(2)"
        >
          本月
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: scanDateRangeDisabled }"
          (click)="scanMoveTabTrack(3)"
        >
          全部
        </div>
      </div>
      <div
        class="tab-track"
        [ngClass]="{
          pos0: scanTabTrackIndex === 0,
          pos1: scanTabTrackIndex === 1,
          pos2: scanTabTrackIndex === 2,
          pos3: scanTabTrackIndex === 3
        }"
      ></div>
    </div>
    <div class="tb-con-filter">
      <app-table-filter
        (handleFilterChange)="scanListSearch($event)"
        [initTableFilters]="scanInitTableFilters"
        [headers]="scanFilterOrderHeaders"
      ></app-table-filter>
    </div>
  </div>

  <div class="tb-wrapper">
    <nz-table
      [nzLoading]="scanListLoading"
      [nzData]="scanListData"
      [nzSize]="'small'"
      [nzShowPagination]="false"
    >
      <thead style="border: 1px solid #e8e8e8">
        <tr>
          <th style="background-color: #ffffff">扫描时间</th>
          <th style="background-color: #ffffff">扫描人</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of scanListData">
          <td>
            <a [routerLink]="['/scan', data.id]">
              {{ dateFormatFunc("yyyy-MM-dd HH:mm:ss", data.createTime) }}
            </a>
          </td>
          <td>{{ data.createUser }}</td>
        </tr>
      </tbody>
    </nz-table>
    <div style="height: 20px"></div>

    <app-table-page
      [total]="scanListMap.total"
      [pageSizeOptions]="pageSizeOptions"
      [current]="scanListPostParams.page"
      [pageSize]="scanListPostParams.size"
      (handlePageIndexChange)="handleScanListPageIndexChange($event)"
      (handlePageSizeChange)="handleScanListPageSizeChange($event)"
    >
    </app-table-page>
  </div>
</div>
