<div class="page-wrapper">
  <app-table
    [scrollWidth]="ListScrollWidth"
    [selectbox]="false"
    [Loading]="ListLoading"
    [listHeader]="listHeader"
    myNzAlign="customerAddress"
    [ListData]="listMaterial"
    [scrollOptions]="ListHeaderWidths"
    [scroll]="true"
    (ClickRow)="handleClickRow($event)"
    link="submitAt"
  >
    <div name="header">
      <span class="span" (click)="handleExportList()">
        <svg
          t="1636970250370"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1882"
          width="16"
          height="16"
          style="vertical-align: text-top; margin-right: 6px"
        >
          <path
            d="M469.333333 682.666667V42.666667c0-23.466667 19.2-42.666667 42.666667-42.666667s42.666667 19.2 42.666667 42.666667v640c0 23.466667-19.2 42.666667-42.666667 42.666666s-42.666667-19.2-42.666667-42.666666"
            p-id="1883"
            fill="#5b6c74"
          ></path>
          <path
            d="M226.210133 268.0576L481.826133 12.433067a42.7776 42.7776 0 0 1 60.330667 0c16.597333 16.597333 16.597333 43.776 0 60.330666L286.5408 328.388267a42.7776 42.7776 0 0 1-60.330667 0 42.7776 42.7776 0 0 1 0-60.330667"
            p-id="1884"
            fill="#5b6c74"
          ></path>
          <path
            d="M542.0544 12.4416l255.616 255.616a42.7776 42.7776 0 0 1 0 60.330667c-16.597333 16.605867-43.776 16.605867-60.330667 0L481.723733 72.789333a42.7776 42.7776 0 0 1 0-60.330666 42.7776 42.7776 0 0 1 60.330667 0M896 1024H128c-70.570667 0-128-57.429333-128-128V640c0-23.594667 19.072-42.666667 42.666667-42.666667s42.666667 19.072 42.666666 42.666667v256c0 23.552 19.114667 42.666667 42.666667 42.666667h768c23.552 0 42.666667-19.114667 42.666667-42.666667V640c0-23.594667 19.072-42.666667 42.666666-42.666667s42.666667 19.072 42.666667 42.666667v256c0 70.570667-57.429333 128-128 128"
            p-id="1885"
            fill="#5b6c74"
          ></path>
        </svg>
        导出
      </span>
    </div>
    <app-table-filter
      name="fliter"
      (handleFilterChange)="handleSearchList($event)"
      [headers]="Headers"
    ></app-table-filter>
    <app-table-page
      name="pagination"
      [total]="Pagination.total"
      [pageSizeOptions]="Pagination.pageSizeOptions"
      [current]="Pagination.pageIndex"
      [pageSize]="Pagination.pageSize"
      (handlePageIndexChange)="PageIndexChange($event)"
      (handlePageSizeChange)="PageSizeChange($event)"
    >
    </app-table-page>
  </app-table>
</div>
<!-- 导出页面 -->
<app-export #export></app-export>
