<nav [ngStyle]="navStyle" class="nav-body-wrapper">
  <div style="float: right; margin-right: 40px" (click)="handleSetComponent()">
    <a>权限设置</a>
  </div>
  <div class="nav-body">
    <nz-tabset [nzSelectedIndex]="currentIndex">
      <nz-tab (nzClick)="onSelectChange(0)" nzTitle="打印记录"></nz-tab>
      <nz-tab (nzClick)="onSelectChange(1)" nzTitle="扫描记录"></nz-tab>
      <nz-tab (nzClick)="onSelectChange(2)" nzTitle="用料清单"></nz-tab>
      <nz-tab (nzClick)="onSelectChange(3)" nzTitle="追溯报表"></nz-tab>
    </nz-tabset>
  </div>
</nav>
<app-set #setComponent></app-set>
<router-outlet></router-outlet>
