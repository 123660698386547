import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NzModalService } from "ng-zorro-antd";
import { interval, Subscription } from "rxjs";
import * as moment from "moment";
import { RetrospectStatementService } from "src/app/services/retrospect-statement/retrospect-statement.service";
import { switchMap } from "rxjs/operators";
import { environment } from "src/environments/environment";

enum ExportingStatus {
  SUCCEED,
  RUNNING,
  FAILED,
}

@Component({
  selector: "app-export",
  templateUrl: "./export.component.html",
  styleUrls: ["./export.component.less"],
})
export class ExportComponent implements OnInit {
  constructor(
    private modalService: NzModalService,
    private renderer: Renderer2,
    private fb: FormBuilder,
    private retrospectServers: RetrospectStatementService
  ) {}

  ngOnInit() {
    this.exportTimeForm = this.fb.group({
      timeRange: [null, [Validators.required]],
    });
  }
  @Input()

  // 导出
  exportingStatus = ExportingStatus;
  @Input() exportVisible = false;
  exporting = false;
  exportTimeForm: FormGroup;
  exportSelectedDate: Date;
  exportDetailGetTimer: Subscription = null;
  exportStatus = "";
  exportProgress: number;
  exportErrmsg = "导出失败";
  exportDownloadUrl = "";
  emptyText = "导出结果";

  // 关闭导出模态框
  exportModalClose() {
    if (!this.exporting) return (this.exportVisible = false);
    new Promise((resolve) =>
      this.modalService.confirm({
        nzTitle: "文件导出中，关闭窗口无法正常导出",
        nzContent: "确定关闭吗？",
        nzOnOk: () => {
          this.exportDetailGetTimer.unsubscribe();
          this.exportDetailGetTimer = null;
          this.exportVisible = false;
          this.exportStatus = "";
          this.exportProgress = null;
          this.exportDownloadUrl = "";
        },
      })
    );
  }

  exportDisabledDate = (current: Date): boolean => {
    // current：当前面板上显示的所有日期
    // this.selectedDate：当前选中的日期
    /**
     * true:禁止，false:允许
     */
    if (!current || !this.exportSelectedDate) return false;
    const offsetV31 = 31 * 24 * 3600 * 1000; // 31天
    const offsetV30 = 30 * 24 * 3600 * 1000; // 30天
    const selectV = Date.parse(this.exportSelectedDate.toString());
    const currentV = Date.parse(current.toString());
    return this.calcMinus(currentV, offsetV30) > selectV ||
      this.calcAdd(currentV, offsetV31) < selectV
      ? true
      : false;
  };

  exportDateOpen(open: boolean) {
    this.exportSelectedDate = null;
  }

  exportCalendarChange(dates: Date[]) {
    if (!dates || !dates.length) return;
    this.exportSelectedDate = dates[0];
  }

  // 导出
  handleExport() {
    for (let i in this.exportTimeForm.controls) {
      this.exportTimeForm.controls[i].markAsDirty();
      this.exportTimeForm.controls[i].updateValueAndValidity();
    }
    if (this.exportTimeForm.status === "INVALID") return;
    if (this.exporting) return;
    this.exporting = true;
    this.exportProgress = 0;
    const value = this.exportTimeForm.value.timeRange;
    const startTime = Date.parse(moment(value[0]).startOf("day").toString());
    const endTime = Date.parse(moment(value[1]).endOf("day").toString());
    this.retrospectServers.exportReport(startTime, endTime).subscribe(
      (data) => {
        const { batchId } = data;
        this.doSetInterval(batchId);
      },
      (err) => {
        this.exporting = false;
      }
    );
  }

  downloadLink: string;

  doSetInterval(jobId: string) {
    const interval$ = interval(1000);
    this.exportDetailGetTimer = interval$
      .pipe(
        switchMap(() => {
          return this.retrospectServers.exportReportProgressBar(jobId);
        })
      )
      .subscribe((res) => {
        const { status } = res;
        this.exportProgress = res.percentage;
        if (status !== ExportingStatus[1]) {
          this.exportDetailGetTimer.unsubscribe();
          if (status === ExportingStatus[0]) {
            this.exportDownloadUrl = `${location.origin}/${environment.API}/table/file?batch_id=${jobId}`;

            const elem = this.renderer.createElement("a");
            this.renderer.setProperty(elem, "href", this.exportDownloadUrl);
            elem.click();
            this.emptyText = "导出成功";
          }
          if (status === ExportingStatus[2]) {
            const { errmsg } = res;
            this.exportErrmsg = errmsg;
            this.emptyText = errmsg;
          }
          setTimeout(() => {
            this.exporting = false;
          }, 500);
        }
      });
  }

  // 下载导出文件
  downloadExportFile() {
    if (!this.exportDownloadUrl) return;
    const elem = this.renderer.createElement("a");
    this.renderer.setProperty(elem, "href", this.exportDownloadUrl);
    elem.click();
  }

  calcMinus(s: number, e: number) {
    return s - e;
  }

  calcAdd(s: number, e: number) {
    return s + e;
  }
}
