import { Component, OnInit } from '@angular/core';
import { PrintService } from 'src/app/services/print/print.service';
import {
  getAmonthFromNow,
  getAweekFromNow,
  getMonthDayOneTime,
  getWeekMondayTime,
} from 'src/app/utils/moment';
import { dateFormat } from 'src/app/utils/timeformat';

interface FilterHeader {
  field: string;
  title: string;
  type: String;
  searchable: boolean;
  data?: Array<{ value: string; text: string; selected: boolean }> | null;
  preRender?: {
    precision?: number;
    format?: string;
  };
  len?: string;
}

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.less'],
})
export class PrintComponent implements OnInit {
  constructor(private printServ: PrintService) {}

  ngOnInit() {
    this.moveTabTrack(0);
  }

  dateFormatFunc = dateFormat;
  dateRangeDisabled = false;
  tabTrackIndex = 0;
  _date = Date.parse(
    new Date(new Date(new Date().toLocaleDateString()).getTime()).toString()
  );
  _edate = Date.parse(
    new Date( // 当天23:59:59
      new Date(new Date().toLocaleDateString()).getTime() +
        24 * 60 * 60 * 1000 -
        1
    ).toString()
  );
  initTableFilters = [];
  _initTableFilters = [];
  printListPostParams = {
    page: 1,
    size: 10,
    createTimeStart: this._date,
    createTimeEnd: this._edate,
    createUser: null,
    totalStart: null,
    totalEnd: null,
  };

  filterOrderHeaders: FilterHeader[] = [
    {
      field: 'createUser',
      title: '打印人',
      type: 'string',
      searchable: true,
    },
    {
      field: 'createTime',
      title: '打印时间',
      type: 'date',
      searchable: true,
    },
    {
      field: 'total',
      title: '标签数量',
      type: 'range',
      searchable: true,
    },
  ];

  // 打印时间自定义筛选
  moveTabTrack(index: number, data?: any) {
    if (this.dateRangeDisabled) return;
    this.tabTrackIndex = index;
    if (index === 0) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: this._date,
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item['field'] === 'createTime')
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item['field'] === 'createTime'
        );
        this.initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: dateFormat('yyyy-MM-dd', this._date),
          max: dateFormat('yyyy-MM-dd', this._edate),
        };
        this._initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: this._date,
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: dateFormat('yyyy-MM-dd', this._date),
            max: dateFormat('yyyy-MM-dd', this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: this._date,
            max: this._edate,
          },
        ];
      }
    }
    if (index === 1) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: getWeekMondayTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item['field'] === 'createTime')
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item['field'] === 'createTime'
        );
        this.initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: dateFormat('yyyy-MM-dd', getAweekFromNow()),
          max: dateFormat('yyyy-MM-dd', this._edate),
        };

        this._initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: getAweekFromNow(),
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: dateFormat('yyyy-MM-dd', getAweekFromNow()),
            max: dateFormat('yyyy-MM-dd', this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: getAweekFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 2) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: getMonthDayOneTime(),
        createTimeEnd: this._edate,
      };
      if (
        this.initTableFilters.some((item) => item['field'] === 'createTime')
      ) {
        const timeInex = this.initTableFilters.findIndex(
          (item) => item['field'] === 'createTime'
        );
        this.initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: dateFormat('yyyy-MM-dd', getAmonthFromNow()),
          max: dateFormat('yyyy-MM-dd', this._edate),
        };
        this._initTableFilters[timeInex] = {
          title: '打印时间',
          field: 'createTime',
          condition: 'between',
          value: getAmonthFromNow(),
          max: this._edate,
        };
        this.initTableFilters = [...this.initTableFilters];
      } else {
        this.initTableFilters = [
          ...this.initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: dateFormat('yyyy-MM-dd', getAmonthFromNow()),
            max: dateFormat('yyyy-MM-dd', this._edate),
          },
        ];
        this._initTableFilters = [
          ...this._initTableFilters,
          {
            title: '打印时间',
            field: 'createTime',
            condition: 'between',
            value: getAmonthFromNow(),
            max: this._edate,
          },
        ];
      }
    }
    if (index === 3) {
      this.printListPostParams = {
        ...this.printListPostParams,
        page: 1,
        createTimeStart: null,
        createTimeEnd: null,
      };
      this.initTableFilters = [];
      this._initTableFilters = [];
      if (data !== undefined) {
        for (let i of data) {
          if (i['condition'] === 'equal') {
            this.initTableFilters.push({
              condition: 'equal',
              field: i['field'],
              value: i['value'],
              title: i['field'] === 'createUser' ? '打印人' : '标签数量',
            });
            this._initTableFilters.push({
              condition: 'equal',
              field: i['field'],
              value: i['value'],
              title: i['field'] === 'createUser' ? '打印人' : '标签数量',
            });
          }
          if (i['condition'] === 'between') {
            this.initTableFilters.push({
              condition: 'between',
              field: i['field'],
              value: i['value'],
              max: i['max'],
              title: '标签数量',
            });
            this._initTableFilters.push({
              condition: 'between',
              field: i['field'],
              value: i['value'],
              max: i['max'],
              title: '标签数量',
            });
          }
        }
      }
    }
    this.getPrintList();
  }

  // 打印筛选
  printListSearch(value: Array<{ [key: string]: string }>) {
    this.printListPostParams = {
      ...this.printListPostParams,
      page: 1,
      createTimeStart: null,
      createTimeEnd: null,
      createUser: null,
      totalStart: null,
      totalEnd: null,
    };
    let flag = false;
    for (let i of value) {
      if (i.field === 'createUser') {
        this.printListPostParams.createUser = i.value;
      }
      if (i.field === 'createTime') {
        if (this.initTableFilters.length) {
          if (
            this.initTableFilters.some((item) => item['field'] === 'createTime')
          ) {
            const timeIndex = this.initTableFilters.findIndex(
              (item) => item['field'] === 'createTime'
            );
            this.printListPostParams.createTimeStart = this._initTableFilters[
              timeIndex
            ]['value'];
            this.printListPostParams.createTimeEnd = this._initTableFilters[
              timeIndex
            ]['max'];
          } else {
            this.printListPostParams.createTimeStart = Number(i.value);
            this.printListPostParams.createTimeEnd = Number(i.max);
          }
        } else {
          this.printListPostParams.createTimeStart = Number(i.value);
          this.printListPostParams.createTimeEnd = Number(i.max);
        }
        flag = true;
      }
      if (i.field === 'total') {
        this.printListPostParams.totalStart = Number(i.value);
        this.printListPostParams.totalEnd = Number(i.max);
      }
    }
    this.dateRangeDisabled = flag;

    if (value.length && !flag) {
      this.moveTabTrack(3, value);
    } else if (!value.length || !flag) {
      this.moveTabTrack(3);
    } else {
      this.getPrintList();
    }
  }

  printListLoading = false;
  instanceId = window.localStorage.getItem('ins');
  printListMap = {
    total: 0,
  };
  printListData: Array<{
    id: number;
    createTime: number;
    createUser: string;
    total: number;
  }> = [];

  // 打印列表
  getPrintList() {
    this.printListLoading = true;
    this.printServ
      .printListGet(this.instanceId, this.printListPostParams)
      .subscribe(
        (data) => {
          this.printListLoading = false;
          const { list, total } = data;
          this.printListMap.total = total;
          this.printListData = list;
        },
        (err) => {
          this.printListLoading = false;
        }
      );
  }

  pageSizeOptions = [10, 15, 20, 30, 40, 50];

  // 处理打印列表页面索引变化
  handlePrintListPageIndexChange(index: number) {
    this.printListPostParams = {
      ...this.printListPostParams,
      page: index,
    };
    this.getPrintList();
  }

  // 处理打印列表页面大小变化
  handlePrintListPageSizeChange(size: number) {
    this.printListPostParams = {
      ...this.printListPostParams,
      size: size,
    };
    this.getPrintList();
  }
}
