import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RetrospectStatementService } from 'src/app/services/retrospect-statement/retrospect-statement.service';

// 处理过滤条件的标头
import { HeaderFilter, ListHeader } from './headers';

import { dateFormat } from 'src/app/utils/timeformat';
import calTextClientWidth from 'src/app/utils/calTextClientWidth';

interface RetrospectList {
  id: number;
  submitAt: number;
  submitUser: string;
  componentCode: string;
  componentName: string;
  batch: string;
  sufBoxCode: string;
  date: string;
  projectNum: string;
  customerPartNum: string;
  englishDescription: string;
  packageNum: string;
  partVersion: string;
  partStatus: string;
  supplierCode: string;
  po: string;
  customerAddress: string;
}

@Component({
  selector: 'app-retrospect-statement',
  templateUrl: './retrospect-statement.component.html',
  styleUrls: ['./retrospect-statement.component.less'],
})
export class RetrospectStatementComponent implements OnInit {
  constructor(
    private router: Router,
    private retrospectServers: RetrospectStatementService
  ) {}
  ngOnInit() {
    this.getRetrospectList();
  }

  Pagination = {
    total: 0,
    pageIndex: 1, //当前页数
    pageSize: 10, //每页条数
    pageSizeOptions: [10, 15, 20, 30, 40, 50],
  };

  // 搜索条件
  page_num: number = 1;
  page_size: number = 10;
  keywords: Array<{
    name: string;
    value: string;
  }> = [];

  getRetrospectList() {
    this.ListLoading = true;
    const { page_num, page_size, keywords } = this;
    this.retrospectServers
      .getRetrospectList(page_num, page_size, keywords)
      .subscribe((data) => {
        this.Pagination.total = data.total;
        this.listMaterial = data.list.map((item) => {
          return {
            id: item.id,
            submitAt: dateFormat('yyyy-MM-dd HH:mm:ss', item.submitAt),
            submitUser: item.submitUser,
            componentCode: item.componentCode,
            componentName: item.componentName,
            batch: item.batch,
            sufBoxCode: item.sufBoxCode,
            date: item.date,
            projectNum: item.projectNum,
            customerPartNum: item.customerPartNum,
            englishDescription: item.englishDescription,
            packageNum: item.packageNum,
            partVersion: item.partVersion,
            partStatus: item.partStatus,
            supplierCode: item.supplierCode,
            po: item.po,
            customerAddress: item.customerAddress,
          };
        });
        this.listMaterial.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (key !== 'id') {
              const title = this.listHeader[key];
              this.ListHeaderWidths[`${key}`] = [
                calTextClientWidth(title) + 35,
              ];
            }
          });
        });
        this.listMaterial.forEach((item) => {
          Object.entries(item).forEach(([key, value]) => {
            if (key !== 'id') {
              this.ListHeaderWidths[`${key}`].push(calTextClientWidth(value));
            }
          });
        });
        let width = 0;
        for (let key in this.ListHeaderWidths) {
          const t = Math.max(...(this.ListHeaderWidths[key] as number[]));
          width += t;
          this.ListHeaderWidths[key] = `${t + 35}px`;
        }
        this.ListScrollWidth = `${width + 100}px`;
        this.ListLoading = false;
      });
  }

  // #region  头部

  // 导出文件
  @ViewChild('export', { static: false }) export: any;
  handleExportList() {
    this.export.exportVisible = true;
  }

  // 筛选
  Headers = HeaderFilter;
  handleSearchList(data) {
    // data是筛选的搜索条件
    this.keywords = data.map((item) => {
      if (item.field == 'submitAt') {
        // submitAt 是包含起始的时间，特别处理
        return {
          name: item.field,
          value: {
            start: item.value,
            end: item.max,
          },
        };
      } else {
        return {
          name: item.field,
          value: item.value,
        };
      }
    });
    this.Pagination = {
      ...this.Pagination,
      pageIndex: 1,
    };
    this.page_num = 1;
    this.getRetrospectList();
  }
  // #endregion

  //#region  表格
  ListLoading = false;
  listHeader = ListHeader;
  listMaterial: Array<RetrospectList>; //用料清单 - list

  ListHeaderWidths: any = {};
  ListScrollWidth = '0px';
  // 点击行跳转
  handleClickRow(row) {
    this.router.navigate(['retrospect', row.id], { skipLocationChange: false });
  }

  //#endregion

  //#region  Pagination  翻页

  // 列表页码发生改变
  PageIndexChange(v) {
    this.page_num = v;
    this.Pagination.pageIndex = v;
    this.getRetrospectList();
  }
  // 列表Size发生改变
  PageSizeChange(v) {
    this.page_size = v;
    this.Pagination.pageSize = v;
    this.getRetrospectList();
  }

  //#endregion
}
