export const ListHeader = {
  id: '总成零件编码',
  submitAt: '提交时间',
  submitUser: '提交人',
  componentCode: '总成零件编码',
  componentName: '总成零件名称',
  batch: '批次',
  sufBoxCode: '隆腾速飞对应箱码',
  date: '日期',
  projectNum: '项目号',
  customerPartNum: '客户零件号',
  englishDescription: '英文描述',
  packageNum: '包装数量',
  partVersion: '零件版本号',
  partStatus: '零件状态号',
  supplierCode: '供应商代码',
  po: 'PO号',
  customerAddress: '客户地址',
};

export const HeaderFilter: Array<FilterHeader> = [
  {
    field: 'submitUser',
    title: '提交人',
    type: 'string',
    searchable: true,
  },
  {
    field: 'submitAt',
    title: '提交时间',
    type: 'date',
    searchable: true,
  },
  {
    field: 'componentCode',
    title: '总成零件编码',
    type: 'string',
    searchable: true,
  },
  {
    field: 'componentName',
    title: '总成零件名称',
    type: 'string',
    searchable: true,
  },
  {
    field: 'sufBoxCode',
    title: '隆腾速飞对应箱码',
    type: 'string',
    searchable: true,
  },
  {
    field: 'projectNum',
    title: '项目号',
    type: 'string',
    searchable: true,
  },
  {
    field: 'customerPartNum',
    title: '客户零件号',
    type: 'string',
    searchable: true,
  },
  {
    field: 'date',
    title: '日期',
    type: 'string',
    searchable: true,
  },
  {
    field: 'batch',
    title: '批次',
    type: 'string',
    searchable: true,
  },
  {
    field: 'englishDescription',
    title: '英文描述',
    type: 'string',
    searchable: true,
  },
  {
    field: 'packageNum',
    title: '包装数量',
    type: 'string',
    searchable: true,
  },
  {
    field: 'partVersion',
    title: '零件版本号',
    type: 'string',
    searchable: true,
  },
  {
    field: 'partStatus',
    title: '零件状态号',
    type: 'string',
    searchable: true,
  },
  {
    field: 'supplierCode',
    title: '供应商代码',
    type: 'string',
    searchable: true,
  },
  {
    field: 'po',
    title: 'PO号',
    type: 'string',
    searchable: true,
  },
  {
    field: 'customerAddress',
    title: '客户地址',
    type: 'string',
    searchable: true,
  },
];

interface FilterHeader {
  field: string;
  title: string;
  type: string;
  searchable: boolean;
  data?: Array<{ value: string; text: string; selected: boolean }> | null;
  preRender?: {
    precision?: number;
    format?: string;
  };
  len?: string;
}
