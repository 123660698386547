import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class SetService {
  constructor(private http: HttpClient) {}

  // 获取密码
  getHttpPassword(id: string) {
    return this.http.get<{
      password: string;
    }>(`${environment.API}/pda/getPassword?openId=${id}`);
  }

  // 更新修改密码
  updateHttpPassword(username: string, newPassword: string) {
    return this.http.get(
      `${environment.API}/pda/updatePassword?username=${username}&newPassword=${newPassword}`
    );
  }
}
