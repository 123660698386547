import { Component, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { SetService } from "src/app/services/set/set.service";

@Component({
  selector: "app-set",
  templateUrl: "./set.component.html",
  styleUrls: ["./set.component.less"],
})
export class SetComponent implements OnInit {
  constructor(
    private setService: SetService,
    private message: NzMessageService
  ) {}

  ngOnInit() {}

  //#region 权限设置设置模态框
  isVisible = false;

  showModal(): void {
    this.isVisible = true;
    this.getPassword();
  }

  handleOk(): void {
    if (this.passworddisable) {
      this.isVisible = false;
      this.message.info("取消");
    } else {
      // 如果有权限，修改密码
      if (this.cache != this.PassWord) {
        this.changePassword();
      } else {
        this.message.info("密码并未修改");
      }
      setTimeout(() => {
        this.isVisible = false;
      }, 1000);
    }
  }

  handleCancel(): void {
    this.isVisible = false;
  }

  //#endregion

  // 密码框
  passwordVisible = false;
  passworddisable = false;
  PassWord: string;
  cache: string;

  // 获取密码
  getPassword() {
    let openId = localStorage.getItem("openId");
    this.setService.getHttpPassword(openId).subscribe(
      (data) => {
        this.PassWord = data.password;
        this.cache = data.password;
        this.passworddisable = false;
      },
      (error) => {
        this.passworddisable = true;
      }
    );
  }

  // 修改密码
  changePassword() {
    if (!this.passworddisable) {
      let username = localStorage.getItem("username");
      const { PassWord } = this;
      PassWord.trim();
      this.setService
        .updateHttpPassword(username, PassWord)
        .subscribe((data) => {
          this.message.info("密码修改成功");
        });
    }
  }
}
