<div class="page-wrapper">
  <app-table
    selectbox="true"
    myNzAlign="code"
    link="name"
    [Loading]="ListLoading"
    (SelectedData)="selectedTable($event)"
    [listHeader]="listHeader"
    [ListData]="listMaterial"
    (ClickRow)="handleClickRow($event.id)"
  >
    <div name="header">
      <a [routerLink]="['/materials/addend']">
        <span class="span"> <i nz-icon nzType="plus"></i>新增</span>
      </a>
      <span class="span" (click)="handleDeleteList()">
        <i nz-icon nzType="delete"></i>删除
      </span>
    </div>
    <app-table-filter
      name="fliter"
      (handleFilterChange)="handleSearchList($event)"
      [headers]="Headers"
    ></app-table-filter>
    <app-table-page
      name="pagination"
      [total]="Pagination.total"
      [pageSizeOptions]="Pagination.pageSizeOptions"
      [current]="Pagination.pageIndex"
      [pageSize]="Pagination.pageSize"
      (handlePageIndexChange)="PageIndexChange($event)"
      (handlePageSizeChange)="PageSizeChange($event)"
    >
    </app-table-page>
  </app-table>
</div>
