import { NgModule } from "@angular/core";

import { LayoutRoutingModule } from "./layout-routing.module";
import { LayoutComponent } from "./layout.component";
import { AppCommonModule } from "src/app/app-common/app-common.module";
import { MaterialsListComponent } from "./materials-list/materials-list.component";
import { RetrospectStatementComponent } from "./retrospect-statement/retrospect-statement.component";
import { MaterialDetailsComponent } from "./materials-list/material-details/material-details.component";
import { RetrospectDetailsComponent } from "./retrospect-statement/retrospect-details/retrospect-details.component";
import { MaterialAddComponent } from "./materials-list/material-add/material-add.component";
import { PrintComponent } from "./print/print.component";
import { ScanComponent } from "./scan/scan.component";
import { RetrospectTableComponent } from "./retrospect-statement/retrospect-details/retrospect-table/retrospect-table.component";
import { TableAddComponent } from "./materials-list/material-add/table-add/table-add.component";
import { ExportComponent } from './retrospect-statement/export/export.component';
import { SetComponent } from './set/set.component';

@NgModule({
  declarations: [
    PrintComponent,
    ScanComponent,
    LayoutComponent,
    MaterialsListComponent,
    RetrospectStatementComponent,
    MaterialDetailsComponent,
    RetrospectDetailsComponent,
    MaterialAddComponent,
    RetrospectTableComponent,
    TableAddComponent,
    ExportComponent,
    SetComponent,
  ],
  imports: [LayoutRoutingModule, AppCommonModule],
})
export class LayoutModule {}
