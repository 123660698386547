import { Location } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.less'],
})
export class LayoutComponent implements OnInit {
  // @Input() currentIndex: number;
  @Input() navStyle: { [key: string]: string } = {};
  @Output() tabSelect = new EventEmitter<number>();
  constructor(private router: Router, private loc: Location) {}
  currentIndex: number;

  route = [
    {
      name: '打印记录',
      path: '/scanner/print',
    },
    {
      name: '扫描记录',
      path: '/scanner/scan',
    },
    {
      name: '用料清单',
      path: '/scanner/materials',
    },
    {
      name: '追溯报表',
      path: '/scanner/retrospect',
    },
  ];

  ngOnInit() {
    this.route.map((i, index) => {
      // 根据刷新时路由路径，加载对应的页面
      if (i.path == location.pathname) {
        this.currentIndex = index;
      }
    });

    // 接受iframe标签传递过来的外部数据~
    fromEvent(window, 'message').subscribe((res: any) => {
      if (res.data.type != 'webpackOk') {
        const { instanceId, openId } = res.data;
        window.localStorage.setItem('ins', instanceId);
        window.localStorage.setItem('openId', openId);
      }
    });
  }

  onSelectChange(index) {
    this.router.navigate([this.route[index].path]);
  }

  // 权限设置弹框
  @ViewChild('setComponent', { static: false }) set: any;
  handleSetComponent() {
    // this.set.isVisible = true;
    this.set.showModal();
  }
}
