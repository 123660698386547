export default function calTextWidth(
  premitiveText: string | number | undefined
): number {
  let width = 0;
  const spanNode = document.createElement('span');
  const containerNode = document.createElement('div');
  spanNode.textContent = premitiveText + '';
  containerNode.appendChild(spanNode);
  containerNode.style.whiteSpace = 'nowrap';
  containerNode.style.width = 'fit-content';
  containerNode.style.position = 'absolute';
  containerNode.style.top = '-9px';
  const body = document.querySelector('body') as HTMLBodyElement;
  body.appendChild(containerNode);
  width = containerNode.clientWidth;
  body.removeChild(containerNode);
  return width;
}
