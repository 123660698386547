import {
  Component,
  OnInit,
  Input,
  Output,
  SimpleChanges,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-table-page",
  templateUrl: "./table-page.component.html",
  styleUrls: ["./table-page.component.scss"],
})
export class TablePageComponent implements OnInit {
  @Input() current: number;
  @Input() total: number;
  @Input() pageSize: number;
  @Input() totalPage: number;
  @Input() pageSizeOptions: number[];
  @Input() showQuickJumper = true;
  @Output() handlePageIndexChange = new EventEmitter<number>();
  @Output() handlePageSizeChange = new EventEmitter<number>();

  selectOption = [10, 15, 20, 30, 40, 50];
  selectedValue = 10;
  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes["total"] === undefined) return;
    this.total = changes["total"].currentValue;
    this.totalPage = Math.ceil(this.total / this.pageSize) || 1;
  }

  ngOnInit() {
    this.totalPage = Math.ceil(this.total / this.pageSize) || 1;
    if (this.selectOption.indexOf(this.pageSize) !== -1) {
      this.selectedValue = this.pageSize;
    }

    if (this.pageSizeOptions) {
      this.selectOption = this.pageSizeOptions;
    }
  }

  doPageIndexChange(value: number) {
    this.handlePageIndexChange.emit(value);
  }

  doPageSizeChange() {
    this.handlePageSizeChange.emit(this.selectedValue);
    this.pageSize = this.selectedValue;
    this.totalPage = Math.ceil(this.total / this.selectedValue) || 1;
  }
}
