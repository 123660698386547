<!-- 导出模态框-->
<nz-modal
  class="export-modal-wrapper"
  nzWidth="966"
  [nzVisible]="exportVisible"
  [nzMaskClosable]="false"
  [nzFooter]="null"
  [nzBodyStyle]="{ paddingBottom: '58px' }"
  nzTitle="导出"
  (nzOnCancel)="exportModalClose()"
>
  <div class="body">
    <div class="form-wrapper">
      <form nz-form nzLayout="inline" [formGroup]="exportTimeForm">
        <nz-form-item>
          <nz-form-label>入库时间 </nz-form-label>
          <nz-form-control [nzErrorTip]="'请选择时间区域再导出'">
            <nz-range-picker
              formControlName="timeRange"
              [nzDisabled]="exporting"
              [nzDisabledDate]="exportDisabledDate"
              (nzOnOpenChange)="exportDateOpen($event)"
              (nzOnCalendarChange)="exportCalendarChange($event)"
              [nzShowTime]="false"
              nzFormat="yyyy-MM-dd"
            ></nz-range-picker>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control>
            <button
              nz-button
              [disabled]="exporting || exportTimeForm.status === 'INVALID'"
              nzType="primary"
              (click)="handleExport()"
            >
              导出
            </button>
          </nz-form-control>
        </nz-form-item>
      </form>
    </div>
    <div class="result-wrapper">
      <ng-container *ngIf="!exporting">
        <ng-container *ngIf="exportStatus === exportingStatus[0]">
          <nz-result
            nzStatus="success"
            nzTitle="导出成功"
            [nzSubTitle]="subTitleTpl"
          >
            <div nz-result-extra>
              <button nz-button nzType="primary" (click)="downloadExportFile()">
                下载
              </button>
            </div>
            <ng-template #subTitleTpl>
              <div style="color: rgba(0, 0, 0, 0.45); text-align: center">
                导出过程中请勿关闭窗口！
              </div>
              <div style="color: rgba(0, 0, 0, 0.45); text-align: center">
                导出完毕后如浏览器未能成功下载导出文件，可点击下方下载按钮再次下载！
              </div>
            </ng-template>
          </nz-result>
        </ng-container>

        <ng-container *ngIf="exportStatus === exportingStatus[2]">
          <nz-result
            nzStatus="error"
            [nzTitle]="exportErrmsg"
            [nzSubTitle]="failSubTitleTpl"
          >
            <div nz-result-extra>
              <button nz-button nzType="primary" disabled>下载</button>
            </div>

            <ng-template #failSubTitleTpl>
              导出失败，可重新点击导出按钮
            </ng-template>
          </nz-result>
        </ng-container>

        <ng-container *ngIf="exportStatus === ''">
          <!-- <app-empty
            [emptyContent]="noExportContentTpl"
            emptyText="导出结果"
          ></app-empty>
          <ng-template #noExportContentTpl>
            <div class="no-export-content-image"></div>
          </ng-template> -->
          <div class="empty-image-wrapper">
            <div class="empty-content">
              <div class="no-export-content-image"></div>
            </div>

            <p class="empty-description">{{ emptyText }}</p>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="exporting">
        <nz-result
          [nzIcon]="exportIconTpl"
          nzStatus="success"
          nzTitle="导出中..."
          [nzSubTitle]="subTitleTpl"
        >
          <ng-template #exportIconTpl>
            <nz-progress
              [nzWidth]="82"
              [nzPercent]="exportProgress * 100"
              nzType="circle"
            ></nz-progress>
          </ng-template>
          <ng-template #subTitleTpl>
            <div style="color: rgba(0, 0, 0, 0.45); text-align: center">
              导出过程中请勿关闭窗口！
            </div>
            <div style="color: rgba(0, 0, 0, 0.45); text-align: center">
              导出完毕后如浏览器未能成功下载导出文件，可点击下方下载按钮再次下载！
            </div>
          </ng-template>
        </nz-result>
      </ng-container>
    </div>
  </div>
</nz-modal>
