<nz-table #nzTable [nzData]="listOfData" [nzShowPagination]="false">
  <thead>
    <tr>
      <th nzShowExpand></th>
      <th>子零件编码</th>
      <th>子零件名称</th>
      <th>数量</th>
      <th>应领数量</th>
      <th>子零件条码</th>
      <th>日期</th>
      <th>批次</th>
    </tr>
  </thead>
  <tbody>
    <ng-template ngFor let-data [ngForOf]="nzTable.data">
      <tr>
        <td
          nzShowExpand
          [(nzExpand)]="mapOfExpandData[data.id]"
          (nzExpandChange)="HandleExpandChange(data, $event)"
        ></td>
        <td>{{ data.subComponentCode }}</td>
        <td>{{ data.subComponentName }}</td>
        <td>{{ data.actualNum }}</td>
        <td>{{ data.num }}</td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <ng-container *ngIf="data.description.length > 0">
        <tr
          [nzExpand]="mapOfExpandData[data.id]"
          *ngFor="let item of data.description"
        >
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{{ item.code }}</td>
          <td>{{ item.date }}</td>
          <td>{{ item.batch }}</td>
        </tr>
      </ng-container>
    </ng-template>
  </tbody>
</nz-table>
