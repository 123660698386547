import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

// 处理过滤条件的标头
import { Header, ListHeader } from "./headers";
import { MaterialsListService } from "src/app/services/materials-list/materials-list.service";

interface MaterialItem {
  code: string;
  name: string;
  id: number;
}

@Component({
  selector: "app-materials-list",
  templateUrl: "./materials-list.component.html",
  styleUrls: ["./materials-list.component.less"],
})
export class MaterialsListComponent implements OnInit {
  constructor(
    private router: Router,
    private materialsService: MaterialsListService
  ) {}
  ngOnInit() {
    this.getMaterialLists();
  }

  Pagination = {
    total: 0,
    pageIndex: 1, //当前页数
    pageSize: 10, //每页条数
    pageSizeOptions: [10, 20, 50, 100],
  };

  //#region  表格
  ListLoading: false;
  listHeader = ListHeader;
  listMaterial: Array<MaterialItem>; //用料清单 - list

  // ID跳转
  handleClickRow(id: number) {
    this.router.navigate(["material", id], { skipLocationChange: false });
  }

  //#endregion

  // 查询参数
  params: {
    code?: string;
    name?: string;
    page_num: number;
    page_size: number;
  } = {
    code: "",
    name: "",
    page_num: this.Pagination.pageIndex,
    page_size: this.Pagination.pageSize,
  };
  // 获取列表
  getMaterialLists() {
    this.materialsService.getMaterialList(this.params).subscribe((data) => {
      this.Pagination.total = data.total;
      this.listMaterial = data.list.map((item) => {
        return <MaterialItem>{
          name: item.name,
          code: item.code,
          id: item.id,
        };
      });
    });
  }
  //#region  分页功能

  // 列表页码发生改变
  PageIndexChange(v: number) {
    this.Pagination.pageIndex = v;
    this.params.page_num = v;
    this.getMaterialLists();
  }
  // 列表Size发生改变
  PageSizeChange(v: number) {
    this.Pagination.pageSize = v;
    this.params.page_size = v;
    this.getMaterialLists();
  }

  //#endregion

  // #region  选中- 删除- 筛选-

  // 筛选
  Headers = Header;
  handleSearchList(keyword) {
    // data是筛选的搜索条件
    keyword.forEach((element) => {
      switch (element.field) {
        case "code":
          this.params.code = element.value;
          break;

        case "name":
          this.params.name = element.value;
          break;

        default:
          break;
      }
    });
    if (keyword.length == 0) {
      this.params.code = "";
      this.params.name = "";
    }
    this.Pagination = {
      ...this.Pagination,
      pageIndex: 1,
    };
    this.params.page_num = 1;
    this.getMaterialLists();
  }

  selectedTableList: Array<MaterialItem> = []; //选中需要删除的列表
  // 选中的内容
  selectedTable(v: Array<MaterialItem>) {
    this.selectedTableList = v;
  }

  // 删除
  handleDeleteList() {
    if (this.selectedTableList.length > 0) {
      this.selectedTableList.forEach((item) => {
        this.materialsService
          .deleteMaterialItem([`${item.id}`])
          .subscribe((data) => {
            // 更新页面
            this.getMaterialLists();
          });
      });
    }
  }
  // #endregion
}
