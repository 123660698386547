import { NgModule } from '@angular/core';
import { AppCommonModule } from 'src/app/app-common/app-common.module';

import { ScannerScanDetailRoutingModule } from './scanner-scan-detail-routing.module';
import { ScannerScanDetailComponent } from './scanner-scan-detail.component';

@NgModule({
  declarations: [ScannerScanDetailComponent],
  imports: [AppCommonModule, ScannerScanDetailRoutingModule],
})
export class ScannerScanDetailModule {}
