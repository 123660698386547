export const ListHeader = {
  id: "id",
  name: "总成零件编码",
  code: "总成零件名称",
};

export const Header: Array<FilterHeader> = [
  {
    field: "code",
    title: "总成零件编码",
    type: "string",
    searchable: true,
  },
  {
    field: "name",
    title: "总成零件名称",
    type: "string",
    searchable: true,
  },
];

interface FilterHeader {
  field: string;
  title: string;
  type: String;
  searchable: boolean;
  data?: Array<{ value: string; text: string; selected: boolean }> | null;
  preRender?: {
    precision?: number;
    format?: string;
  };
  len?: string;
}
