import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpEventType,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  Observable,
  of,
  throwError,
  TimeoutError,
  BehaviorSubject,
  EMPTY,
} from 'rxjs';
import {
  map,
  catchError,
  timeout,
  switchMap,
  retry,
  filter,
  take,
  concatMap,
} from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {
  timer = null;
  logoutCount = 3;
  _messge;
  isTokenRefreshing = false; // 刷新token标志
  isIoTubeTokenRefreshing = false; // 刷新isIOTubeToken标记
  isPrd: boolean;
  isStopRequest: boolean; // 停止后续请求标记

  constructor(
    private message: NzMessageService,
    private router: Router,
    private modalService: NzModalService
  ) {
    this.isPrd = environment.production;
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.handle(req, next, this);
  }

  handle(
    req: HttpRequest<any>,
    next: HttpHandler,
    ref: any
  ): Observable<HttpEvent<any>> {
    const timeoutValue = 120000; // 超时时间
    return next.handle(req).pipe(
      timeout(timeoutValue),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // 业务异常,抛错
          if (req.responseType === 'blob') {
            return event.clone({
              body: event.body.body,
            });
          }
          if (
            event.body.code === undefined &&
            event.body.message === undefined &&
            event.body.statecode !== undefined &&
            event.body.statename !== undefined
          ) {
            // 处理旧账套跳转
            this.router.navigate(
              [
                'exception',
                {
                  status: -1,
                },
              ],
              { skipLocationChange: false }
            );
            return;
          }
          if (event.body.code !== '0') {
            if (
              req.headers.has('partial') &&
              req.headers.get('partial') === 'true'
            ) {
              throw new BizError(event.body.message);
            }
            this.message.create('error', event.body.message);
            throw new BizError(event.body.message);
          }

          // 如果有result,返回result
          if (event.body.result && event.body.result !== null) {
            return event.clone({
              body: event.body.result,
            });
          }
          // 如果没有result, 返回null
          return event.clone({
            body: null,
          });
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        return this.handleError(error, req, next, ref);
      })
    );
  }

  /**
   * 异常处理
   */
  private handleError(
    event: HttpResponse<any> | HttpErrorResponse | BizError,
    req: HttpRequest<any>,
    next: HttpHandler,
    ref: boolean
  ): Observable<any> {
    // 超时，直接返回
    if (event instanceof TimeoutError) {
      // this.message.create('error', '超时了！');
      this.router.navigate(
        [
          'exception',
          {
            status: 500,
          },
        ],
        { skipLocationChange: false }
      );
      return;
    }

    // 业务异常,直接返回
    if (event instanceof BizError) {
      return throwError(event);
    }

    // 处理 3xx、4xx、5xx 错误
    if (!this.isPrd) {
      console.info(`event.status: ${event.status}`);
    }

    // 登录超时
    if (event.status === 403) {
      return;
    }

    // 跳转到错误提示页面
    this.router.navigate(
      [
        'exception',
        {
          status: event.status,
        },
      ],
      { skipLocationChange: false }
    );
  }
}

/**
 * 业务异常
 */
export class BizError implements Error {
  readonly name = 'BizError';
  message: string;

  constructor(message: string) {
    this.message = message;
  }
}
