<a [href]="downloadLink" id="clickme"></a>
<div class="viewbg">
  <div style="padding: 5px 20px 20px 20px">
    <span class="span" (click)="handleExport()">
      <svg
        t="1636970250370"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="1882"
        width="16"
        height="16"
        style="vertical-align: text-top; margin-right: 6px"
      >
        <path
          d="M469.333333 682.666667V42.666667c0-23.466667 19.2-42.666667 42.666667-42.666667s42.666667 19.2 42.666667 42.666667v640c0 23.466667-19.2 42.666667-42.666667 42.666666s-42.666667-19.2-42.666667-42.666666"
          p-id="1883"
          fill="#5b6c74"
        ></path>
        <path
          d="M226.210133 268.0576L481.826133 12.433067a42.7776 42.7776 0 0 1 60.330667 0c16.597333 16.597333 16.597333 43.776 0 60.330666L286.5408 328.388267a42.7776 42.7776 0 0 1-60.330667 0 42.7776 42.7776 0 0 1 0-60.330667"
          p-id="1884"
          fill="#5b6c74"
        ></path>
        <path
          d="M542.0544 12.4416l255.616 255.616a42.7776 42.7776 0 0 1 0 60.330667c-16.597333 16.605867-43.776 16.605867-60.330667 0L481.723733 72.789333a42.7776 42.7776 0 0 1 0-60.330666 42.7776 42.7776 0 0 1 60.330667 0M896 1024H128c-70.570667 0-128-57.429333-128-128V640c0-23.594667 19.072-42.666667 42.666667-42.666667s42.666667 19.072 42.666666 42.666667v256c0 23.552 19.114667 42.666667 42.666667 42.666667h768c23.552 0 42.666667-19.114667 42.666667-42.666667V640c0-23.594667 19.072-42.666667 42.666666-42.666667s42.666667 19.072 42.666667 42.666667v256c0 70.570667-57.429333 128-128 128"
          p-id="1885"
          fill="#5b6c74"
        ></path>
      </svg>
      导出
    </span>
    <a [routerLink]="['/scanner/retrospect']">
      <i
        nz-icon
        nzType="close"
        style="float: right; font-size: 16px"
        nzTheme="outline"
      ></i
    ></a>
  </div>
  <div style="padding-bottom: 20px">
    <ul
      nz-menu
      nzMode="horizontal"
      style="padding: 0px 40px; font-weight: 700; color: black"
    >
      总成零件
    </ul>
    <ul style="padding: 10px 30px; background: #ffffff">
      <div nz-row *ngIf="FinishedProduct">
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>总成零件编码： </span>{{ FinishedProduct.componentCode }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>总成零件名称： </span>{{ FinishedProduct.componentName }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>隆腾速飞对应箱码： </span>{{ FinishedProduct.sufBoxCode }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>总成零件条码： </span>{{ FinishedProduct.componentBarCode }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>客户零件号： </span>{{ FinishedProduct.customerPartNum }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>项目号： </span>{{ FinishedProduct.projectNum }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>包装数量： </span>{{ FinishedProduct.packageNum }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>英文描述： </span>{{ FinishedProduct.englishDescription }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>供应商代码： </span>{{ FinishedProduct.supplierCode }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>PO号： </span>{{ FinishedProduct.po }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>其他： </span>{{ FinishedProduct.other }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>客户地址： </span>{{ FinishedProduct.customerAddress }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>自定义字段： </span>{{ FinishedProduct.customField }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>天数： </span>{{ FinishedProduct.days }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>批次： </span>{{ FinishedProduct.batch }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <!-- <span>日期： </span>{{ FinishedProduct.date | date: "yyyy-MM-dd" }} -->
          <span>日期： </span>{{ FinishedProduct.date }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>零件版本号： </span>{{ FinishedProduct.partVersion }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>零件状态号： </span>{{ FinishedProduct.partStatus }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>提交时间： </span
          >{{ FinishedProduct.submitAt | date: "yyyy-MM-dd HH:mm:ss" }}
        </div>
        <div nz-col nzSpan="6" style="margin: 15px 0px">
          <span>提交人： </span>{{ FinishedProduct.submitUser }}
        </div>
      </div>
    </ul>
  </div>

  <div>
    <ul
      nz-menu
      nzMode="horizontal"
      style="padding: 0px 40px; font-weight: 700; color: black"
    >
      子零件信息
    </ul>
    <ul nz-menu style="padding: 25px">
      <app-retrospect-table [detailID]="detailID"></app-retrospect-table>
    </ul>
  </div>
</div>
