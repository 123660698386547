import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RetrospectStatementService {
  constructor(private http: HttpClient) {}

  //获取追溯报表列表 - 包含查询
  getRetrospectList(
    page_num: number = 1,
    page_size: number = 15,
    keywords: Array<{
      name: string;
      value: string;
    }> = []
  ) {
    return this.http.post<{
      total: number;
      list: Array<{
        id: number;
        submitAt: number;
        submitUser: string;
        componentCode: string;
        componentName: string;
        batch: string;
        sufBoxCode: string;
        date: string;
        projectNum: string;
        customerPartNum: string;
        englishDescription: string;
        packageNum: string;
        partVersion: string;
        partStatus: string;
        supplierCode: string;
        po: string;
        customerAddress: string;
      }>;
    }>(`${environment.API}/table`, { page_num, page_size, keywords });
  }

  // 获取追溯报表详情- 表头
  getRetrospectDetailHeader(id: number) {
    return this.http.get<{
      actualNum: number;
      barcodeNum: number;
      batch: string;
      componentBarCode: string;
      componentCode: string;
      componentName: string;
      createAt: number;
      customField: string;
      customerAddress: string;
      customerPartNum: string;
      date: number;
      days: number;
      englishDescription: string;
      id: number;
      num: number;
      other: string;
      packageNum: number;
      partStatus: string;
      partVersion: string;
      po: string;
      projectNum: string;
      subComponentBarCode: string;
      subComponentCode: string;
      subComponentName: string;
      submitAt: number;
      submitUser: string;
      sufBoxCode: string;
      supplierCode: string;
      updateAt: number;
    }>(`${environment.API}/table/head?id=${id}`);
  }

  // 获取追溯报表详情- 表体
  getRetrospectDetailBody(id: number) {
    return this.http.get<{
      total: string;
      list: Array<{
        num: number;
        subComponentCode: string;
        actualNum: number;
        subComponentName: string;
      }>;
    }>(`${environment.API}/table/detail?id=${id}`);
  }

  // 获取子零件条码
  getDetailedBarcode(id: number, code: string) {
    return this.http.get<{
      total: number;
      list: Array<{
        code: string;
        date: string;
        batch: string;
      }>;
    }>(
      `${environment.API}/table/detail/code?table_id=${id}&sub_component_code=${code}`
    );
  }

  // 导出报表
  exportReport(start: number, end: number) {
    return this.http.get<{
      batchId: string;
    }>(`${environment.API}/table/export?start=${start}&end=${end}`);

    // return new Observable((observer) => {
    //   observer.next("fjhdbsfhjgbdkgfjh报表导出结果");
    // });
  }

  exportReportId(id: number) {
    return this.http.get<{
      batchId: string;
    }>(`${environment.API}/table/export?id=${id}`);
  }

  // 导出报表进度
  exportReportProgressBar(batch_id: string) {
    return this.http.get<{
      percentage: number;
      status: string;
      errmsg: string;
    }>(`${environment.API}/table/progress?batch_id=${batch_id}`);
  }

  // 下载报表
  exportReportFile(batch_id: string) {
    return this.http.get<{}>(
      `${environment.API}/table/file?batch_id=${batch_id}`,
      {
        // responseType: "application/octet-stream",
      }
    );
  }
}
