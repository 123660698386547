import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ScanService {
  constructor(private http: HttpClient) {}

  // 获取扫描列表
  scanListGet(
    instanceId: string,
    params: {
      page: number;
      size: number;
      createTimeStart: number;
      createTimeEnd: number;
      createUser: string;
    }
  ) {
    return this.http
      .post<{
        total: number;
        list: Array<{
          id: number;
          createTime: number;
          createUser: string;
        }>;
      }>(
        `/${environment.API}/scan/list`,
        { ...params },
        {
          headers: {
            instanceId,
          },
        }
      )
      .pipe(map((res) => res));
  }

  // 扫描详情数据
  scanDetailGet(
    instanceId: string,
    page: number,
    size: number,
    scanId: string
  ) {
    return this.http
      .get<{
        total: number;
        list: Array<{ [key: string]: any }>;
        titles: Array<{
          name: string;
          label: string;
        }>;
      }>(
        `/${environment.API}/scan/detail?page=${page}&size=${size}&scanId=${scanId}`,
        {
          headers: {
            instanceId,
          },
        }
      )
      .pipe(map((res) => res));
  }
}
