import { NgModule } from "@angular/core";
import { LayoutModule } from "./layout/layout.module";
import { ScannerPrintDetailModule } from "./layout/print/scanner-print-detail/scanner-print-detail.module";
import { ScannerScanDetailModule } from "./layout/scan/scanner-scan-detail/scanner-scan-detail.module";

@NgModule({
  declarations: [],
  imports: [LayoutModule, ScannerPrintDetailModule, ScannerScanDetailModule],
  exports: [LayoutModule, ScannerPrintDetailModule, ScannerScanDetailModule],
})
export class PagesModule {}
