<div class="tb-filter-container">
  <div>
    <ng-content select="[name=header]"></ng-content>
  </div>
  <div class="tb-con-filter">
    <ng-content select="[name=fliter]"></ng-content>
  </div>
</div>
<div class="tb-wrapper">
  <ng-container *ngIf="scroll">
    <nz-table
      [nzLoading]="Loading"
      [nzData]="ListData"
      [nzSize]="nzSize"
      [nzShowPagination]="false"
      [nzScroll]="{ x: scrollWidth }"
    >
      <thead>
        <tr>
          <th
            *ngIf="selectbox"
            [nzShowCheckbox]="selectbox"
            [(nzChecked)]="isAllDisplayDataChecked"
            (nzCheckedChange)="checkAll($event)"
          ></th>
          <ng-container *ngFor="let key of objectKeys(listHeader)">
            <th
              [nzWidth]="scrollOptions[key]"
              style="font-weight: 600"
              [nzAlign]="myNzAlignRight(key)"
              *ngIf="key != 'id'"
            >
              {{ listHeader[key] }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ListData">
          <td
            *ngIf="selectbox"
            [nzShowCheckbox]="selectbox"
            [(nzChecked)]="mapOfCheckedId[data.id]"
            (nzCheckedChange)="refreshStatus()"
            style="width: 50px"
          ></td>

          <ng-container *ngFor="let key of objectKeys(data)">
            <td [nzAlign]="myNzAlignRight(key)" *ngIf="key != 'id'" nzBreakWord>
              <a
                *ngIf="key == link; else templateName"
                (click)="handleClickRow(data)"
                >{{ data[key] }}
              </a>
              <ng-template #templateName>{{ data[key] }}</ng-template>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>
  <ng-container *ngIf="!scroll">
    <nz-table
      [nzLoading]="Loading"
      [nzData]="ListData"
      [nzSize]="nzSize"
      [nzShowPagination]="false"
    >
      <thead>
        <tr>
          <th
            *ngIf="selectbox"
            [nzShowCheckbox]="selectbox"
            [(nzChecked)]="isAllDisplayDataChecked"
            (nzCheckedChange)="checkAll($event)"
          ></th>
          <ng-container *ngFor="let key of objectKeys(listHeader)">
            <th
              style="font-weight: 600"
              [nzAlign]="myNzAlignRight(key)"
              *ngIf="key != 'id'"
            >
              {{ listHeader[key] }}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of ListData">
          <td
            *ngIf="selectbox"
            [nzShowCheckbox]="selectbox"
            [(nzChecked)]="mapOfCheckedId[data.id]"
            (nzCheckedChange)="refreshStatus()"
            style="width: 50px"
          ></td>

          <ng-container *ngFor="let key of objectKeys(data)">
            <td [nzAlign]="myNzAlignRight(key)" *ngIf="key != 'id'">
              <a
                *ngIf="key == link; else templateName"
                (click)="handleClickRow(data)"
                >{{ data[key] }}
              </a>
              <ng-template #templateName>{{ data[key] }}</ng-template>
            </td>
          </ng-container>
        </tr>
      </tbody>
    </nz-table>
  </ng-container>

  <ng-content select="[name=pagination]"></ng-content>
</div>
