import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MaterialsListService {
  constructor(private http: HttpClient) {}

  // 获取用料清单列表
  getMaterialList(
    params: {
      page_num?: number;
      page_size?: number;
      name?: string;
      code?: string;
    },
    wpId: string = "60"
  ) {
    return this.http
      .get<{
        total: number;
        list: Array<{
          code: string;
          name: string;
          updateAt: string;
          id: number;
          createAt: number;
          barCode: string;
        }>;
      }>(
        `${environment.API}/material?page_num=${params.page_num}&page_size=${params.page_size}&name=${params.name}&code=${params.code}`,
        {
          headers: {
            wpId: wpId,
          },
        }
      )
      .pipe(map((res) => res));
  }

  // 删除选中用料清单
  deleteMaterialItem(ids: Array<string>) {
    const options = {
      headers: new HttpHeaders(),
      body: ids,
    };
    return this.http.delete(`${environment.API}/material`, options);
    // return new Observable((observer) => {
    //   observer.next("空，就是删除成功");
    // });
  }

  // 获取搜索结果的清单列表
  getSearchMaterialList(
    code: string,
    wpId: number = 60,
    page_size: number = 10
  ) {
    return this.http.get<{
      total: number;
      list: Array<{
        id: number;
        name: string;
        code: string;
      }>;
    }>(
      `${environment.API}/material/search?wpId=${wpId}&page_size=${page_size}&code=${code}`
    );
  }

  //获取用料清单详情-表头
  getMaterialDetailHeader(id: number) {
    return this.http.get<{
      list: Array<{
        componentCode: string;
        componentName: string;
      }>;
    }>(`${environment.API}/material/detail/head?id=${id}`);
  }

  // 获取用料清单详情- 表体
  getMaterialDetailBody(
    component_id: number,
    page_num: number = 1,
    page_size: number = 15
  ) {
    return this.http.get<{
      total: number;
      list: Array<{
        subComponentId: number;
        subComponentName: string;
        subComponentCode: string;
        subComponentNum: number;
      }>;
    }>(
      `${environment.API}/material/detail/list?component_id=${component_id}&page_num=${page_num}&page_size=${page_size}`
    );
  }

  // 新建或编辑 用料清单
  changeOrNewMaterialDetailItem(params: {
    component: {
      id: number;
      code: string;
      name: string;
    };
    list: Array<{
      id: number;
      num: number;
      code: string;
      name: string;
    }>;
  }) {
    return this.http.post<{}>(`${environment.API}/material/create`, {
      ...params,
    });
  }
}
