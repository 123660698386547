import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

interface IdList {
  id: number;
  [key: string]: any;
}

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.less'],
})
export class TableComponent implements OnInit {
  constructor() {}
  ngOnInit() {}

  objectKeys = Object.keys;
  @Input() Loading = false;
  @Input() scroll = false;
  @Input() myNzAlign: string;
  @Input() listHeader: {};
  @Input() nzSize: string = 'small';
  @Input() scrollOptions: any[] = [];
  @Input() scrollWidth: number = 0;

  //本组件邀请listData有一个Id ~
  @Input() ListData: IdList[]; //本组件邀请listData有一个Id ~

  //#region   表格前面的选中框
  @Input() selectbox: boolean = false; // 选择框

  isAllDisplayDataChecked = false; //全部选中
  mapOfCheckedId: { [key: string]: boolean } = {};
  // 刷新状态
  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.ListData.every(
      (item) => this.mapOfCheckedId[item.id]
    );
    this.sendAllSelectedData();
  }
  // 全选
  checkAll(value: boolean): void {
    this.ListData.forEach((item) => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  // 发送所有选中的数据
  @Output() SelectedData = new EventEmitter<{}>();

  // 取出所有选中的data,并发送出去
  sendAllSelectedData() {
    let ids = [];
    let checkedList = [];
    const { mapOfCheckedId, ListData } = this;
    for (const key in mapOfCheckedId) {
      if (mapOfCheckedId[key]) {
        ids.push(key);
      }
    }
    ids.map((item) => {
      ListData.forEach((i) => {
        if (+item == i.id) {
          checkedList.push(i);
        }
      });
    });
    this.SelectedData.emit(checkedList);
  }

  //#endregion

  // 最后一行靠右对齐
  myNzAlignRight(key: string): string {
    if (key == this.myNzAlign) {
      return 'right';
    } else {
      return 'left';
    }
  }

  // 传入 - 需要链接的字段键名
  @Input() link: string;

  // 点击行
  @Output() ClickRow = new EventEmitter<{}>();
  handleClickRow(row) {
    this.ClickRow.emit(row);
  }
}
