import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MaterialsListService } from "src/app/services/materials-list/materials-list.service";

interface Item {
  id: number;
  name: string;
  code: string;
  label: string;
}

@Component({
  selector: "app-material-add",
  templateUrl: "./material-add.component.html",
  styleUrls: ["./material-add.component.less"],
})
export class MaterialAddComponent implements OnInit {
  constructor(
    private activateInfo: ActivatedRoute,
    private materialsService: MaterialsListService,
    private message: NzMessageService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAmendId();
    this.getMaterialOptionSearch();
    this.amendId ? this.getMaterialHeader() : "";

    // 搜索防抖
    this.SearchAntiShake();
  }

  // 编辑对象的ID
  amendId: number;
  getAmendId() {
    this.activateInfo.paramMap.subscribe((queryParams: any) => {
      this.amendId = queryParams.params.id;
    });
  }

  // 搜索条件
  code: string;
  page_num: number;
  page_size: number;

  // 获取编码
  getMaterialOptionSearch(value: string = "") {
    this.materialsService.getSearchMaterialList(value).subscribe((data) => {
      let option = data.list.map((item) => {
        const { code, name } = item;
        return { ...item, label: `${code} (${name})` };
      });
      this.initOptionlist = option;
      this.optionList = option;
    });
  }

  // 获取总成零件
  getMaterialHeader() {
    this.materialsService
      .getMaterialDetailHeader(this.amendId)
      .subscribe((data) => {
        const { componentCode: code, componentName: name } = data.list[0];
        this.value = name;
        this.selectedCode = code;
        const { amendId } = this;
        this.optionList = [
          ...this.optionList,
          { id: amendId, name, code, label: `${code} (${name})` },
        ];
      });
  }

  // 新建

  // 编辑

  //#region
  initOptionlist: Array<Item> = [];
  optionList: Array<Item> = [];

  isLoading = true;
  value: string = "自动带出数据";
  ValueId: number;

  // 滚动到底部后
  loadMore(): void {
    // console.log("滚动底部事件");
  }

  // 隐藏提示
  displayTips = true;

  $searchText: Subject<string> = new Subject<string>();
  SearchAntiShake() {
    this.$searchText
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.getMaterialOptionSearch(value);
        this.displayTips = false;
      });
  }

  // 搜索
  onSearch(value: string): void {
    if (value) {
      this.$searchText.next(value);
    } else {
      this.optionList = this.initOptionlist;
      this.displayTips = true;
    }
  }

  @ViewChild("addtable", { static: true }) addtable: any;
  // 保存
  saveEdit() {
    if (!this.addtable.editNow) {
      let isOk = this.selectedCode && this.addtable.table.length > 0;

      if (isOk) {
        const { selectedCode } = this;
        const { table } = this.addtable;

        let idList = table.map((i: any) => i.id);
        this.materialsService
          .getSearchMaterialList(selectedCode)
          .subscribe((data) => {
            const { id, name, code } = data.list[0];
            if (idList.includes(id)) {
              this.message.warning("总成零件不能成为子零件");
            } else {
              let params = {
                component: { id, code, name },
                list: [...this.addtable.table],
              };
              this.materialsService
                .changeOrNewMaterialDetailItem(params)
                .subscribe((data) => {
                  this.message.info("保存成功");
                  this.router.navigate(["scanner/materials"], {
                    skipLocationChange: false,
                  });
                });
            }
          });
      } else {
        this.message.warning("请正确填写信息");
      }
    } else {
      this.message.warning("请保存编辑");
    }
  }

  selectedCode: string = null;
  //修改选中的值
  modelChange(event: string) {
    // this.value = event;
    this.materialsService.getSearchMaterialList(event).subscribe((data) => {
      let item: any = data.list.filter((item) => item.code == event);
      this.value = item.length > 0 ? item[0].name : "选择编码自动带出";
    });
  }

  //#endregion
}
