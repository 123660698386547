/**
 * 获取当前时间一周前的时间
 */
export const getAweekFromNow = (): number => {
  const currentDate = Date.parse(new Date().toString());
  const oneweekdate = new Date(currentDate - 7 * 24 * 3600 * 1000);
  const y = oneweekdate.getFullYear();
  const m = oneweekdate.getMonth() + 1;
  const d = oneweekdate.getDate();
  const formatwdate = `${m}/${d}/${y}`;
  return Date.parse(new Date(formatwdate).toString());
};

/**
 * 获取当前时间一个月前的时间
 */
export const getAmonthFromNow = (): number => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 1);
  const y = currentDate.getFullYear();
  const m = currentDate.getMonth() + 1;
  const d = currentDate.getDate();
  const formatwdate = `${m}/${d}/${y}`;
  return Date.parse(new Date(formatwdate).toString());
};

/**
 * 获取当前时间半年前的时间
 */
export const getHalfYearFromNow = (): number => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 6);
  const y = currentDate.getFullYear();
  const m = currentDate.getMonth() + 1;
  const d = currentDate.getDate();
  const formatwdate = `${m}/${d}/${y}`;
  return Date.parse(new Date(formatwdate).toString());
};

/**
 * 获取当前时间一年前的时间
 */
export const getAyearFromNow = (): number => {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() - 12);
  const y = currentDate.getFullYear();
  const m = currentDate.getMonth() + 1;
  const d = currentDate.getDate();
  const formatwdate = `${m}/${d}/${y}`;
  return Date.parse(new Date(formatwdate).toString());
};

/**
 * 获取当前周周一时间
 */
export const getWeekMondayTime = (): number => {
  const now = new Date();
  const nowTime = now.getTime();
  const day = now.getDay() || 7;
  const oneDayTime = 24 * 60 * 60 * 1000;
  const MondayTime = nowTime - (day - 1) * oneDayTime;
  const timeStamp = Date.parse(new Date(MondayTime).toString());
  const date = new Date(timeStamp);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return Date.parse(date.toString());
};

/**
 * 获取当月第一天时间
 */
export const getMonthDayOneTime = (): number => {
  var data = new Date(); //本月
  data.setDate(1);
  data.setHours(0);
  data.setSeconds(0);
  data.setMinutes(0);

  return data.getTime();
};
