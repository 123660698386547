<div class="table-filter-wrapper">
    <!-- <button *ngIf="showSearchBtn" [disabled]="searchDisabled" nz-button class="search-btn" nzType="primary"
        (click)="handleSearch()">
        查询
    </button> -->
    <div class="dropdown-wrapper" [ngClass]="{'active': dropdownActive}">
        <div [nzClickHide]="false" [nzOverlayStyle]="{'marginTop': '6px'}" [(nzVisible)]="dropdownActive"
            (nzVisibleChange)="dropdownVisibleChange($event)" nz-dropdown [nzDropdownMenu]="tableFilterMenu"
            nzTrigger="click">
            <span class="name"> {{ title }}</span>
            <span class="icon">
                <i nz-icon nzType="down"></i>
            </span>
        </div>

    </div>

    <nz-dropdown-menu #tableFilterMenu="nzDropdownMenu">
        <div nz-menu class="table-filter-menu">
            <div class="input-box" [ngClass]="{'focus': focused}">
                <div class="search-inner-icon" (click)="handleSearch()">
                    <span nz-tooltip nzTooltipTitle="搜索">
                        <i nz-icon nzType="search" style="font-size: 16px" nzTheme="outline"></i>
                    </span>
                </div>
                <div class="search-help-tip" *ngIf="showHelpTip">
                    <span nz-tooltip [nzTooltipTitle]="helpTip">
                        <div class="icon-circle-wrap">
                            <i nz-icon class="help" nzType="question" nzTheme="outline"></i>
                        </div>
                    </span>
                </div>
                <div class="selection-btn" nz-dropdown [nzClickHide]="true" [nzDropdownMenu]="selectionlist">
                    <i nz-icon nzType="caret-down" nzTheme="outline"></i>
                </div>
                <div class="selection-text" [hidden]="!selected">
                    {{selectionText}}：
                </div>
                <nz-dropdown-menu #selectionlist="nzDropdownMenu">
                    <ul nz-menu nzSelectable [ngStyle]="{'max-height':'350px','overflow-y':'auto'}">
                        <li nz-menu-item *ngFor="let item of headers" (click)="handleItemSelect(item)">{{item.title}}
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <input #inputView class="search-input" *ngIf="selectedType === 'string'" [placeholder]="placeholderText"
                    (ngModelChange)="onInputChange($event)" (blur)="onInputBlur()" (focus)="onInputFocus();"
                    [(ngModel)]="inputValue" (keyup.enter)="handleInputEnter()" />

                <input #inputView class="search-input" *ngIf="
                selectedType === 'boolean' " [placeholder]="placeholderText" (ngModelChange)="onInputChange($event)"
                    [(ngModel)]="inputValue" (blur)="onInputBlur()" (focus)="onInputFocus();"
                    (keyup.enter)="handleInputEnter()" />

                <input #inputView class="search-input" *ngIf="
                selectedType === 'select' " readonly [placeholder]="placeholderText"
                    (ngModelChange)="onInputChange($event)" [(ngModel)]="inputValue" (blur)="onInputBlur()"
                    (focus)="onInputFocus();" (keyup.enter)="handleInputEnter()" />

                <input #inputView class="search-input" [placeholder]="placeholderText"
                    (ngModelChange)="onInputChange($event)" *ngIf="selectedType === 'double' || selectedType === 'int'"
                    [ngModel]="inputValue" (keyup.enter)="handleInputEnter()" (blur)="onInputBlur()"
                    (focus)="onInputFocus();" />
                <input #inputView class="search-input" readonly [placeholder]="placeholderText"
                    (ngModelChange)="onInputChange($event)" *ngIf="selectedType === 'range'" [ngModel]="inputValue" />

                <div *ngIf="selectedType === 'date'" style="position: relative; flex: auto;">
                    <input #inputView class="search-input" readonly [placeholder]="placeholderText"
                        (keyup.enter)="handleInputEnter()" (blur)="onInputBlur()" (focus)="onInputFocus();"
                        style="position: relative;z-index:3;width:100%" />
                    <nz-range-picker [nzStyle]="rangePickerstyle" *ngIf="initDatePicker" [ngModel]="dateRange"
                        (ngModelChange)="onDateRangePickerChange($event)"
                        (nzOnOpenChange)="handleDatePickerOpenChange($event)" [nzOpen]="openDatepicker">
                    </nz-range-picker>
                    <nz-range-picker #inputView [nzStyle]="rangePickerstyle" *ngIf="!initDatePicker && openDatepicker"
                        [nzOpen]="openDatepicker" (nzOnOpenChange)="handleDatePickerOpenChange($event)"
                        (ngModelChange)="onDateRangePickerChange($event)" [ngModel]="dateRange">
                    </nz-range-picker>
                </div>
            </div>
            <div class="selection-dropdown-content" *ngIf="openSelectionDropdown && selectedType === 'boolean'">
                <ng-container *ngFor="let bool of boolSelectionMap">
                    <ng-container *ngIf="booleanActiveSelection === bool; else elseTemplate">
                        <div class="select-content active" (click)="handleSelectBoolChange(bool)">
                            <span class="search-icon">
                                <i nz-icon nzType="search" nzTheme="outline"></i>
                            </span>
                            <span class="search-label">
                                {{selectionText}}：
                            </span>
                            <span class="search-value">{{bool.text}}</span>
                        </div>
                    </ng-container>
                    <ng-template #elseTemplate>
                        <div class="select-content" (click)="handleSelectBoolChange(bool)">
                            <span class="search-icon">
                                <i nz-icon nzType="search" nzTheme="outline"></i>
                            </span>
                            <span class="search-label">
                                {{selectionText}}：
                            </span>
                            <span class="search-value">{{bool.text}}</span>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

            <div class="selection-dropdown-content" *ngIf="openSelectionDropdown && selectedType === 'select'">
                <ng-container *ngFor="let item of selectionDataList">
                    <ng-container *ngIf="dropdownActiveSelection === item; else sTemplate">
                        <div class="select-content active" (click)="handleSelectChange(item)">
                            <span class="search-icon">
                                <i nz-icon nzType="search" nzTheme="outline"></i>
                            </span>
                            <span class="search-label">
                                {{selectionText}}：
                            </span>
                            <span class="search-value">{{item.text}}</span>
                        </div>
                    </ng-container>
                    <ng-template #sTemplate>
                        <div class="select-content" (click)="handleSelectChange(item)">
                            <span class="search-icon">
                                <i nz-icon nzType="search" nzTheme="outline"></i>
                            </span>
                            <span class="search-label">
                                {{selectionText}}：
                            </span>
                            <span class="search-value">{{item.text}}</span>
                        </div>
                    </ng-template>
                </ng-container>
            </div>

            <!---->
            <div class="selection-dropdown-content" *ngIf="openSelectionDropdown && selectedType === 'range'">
                <div class="select-content range">
                    <span class="range-start-input range-input">
                        <nz-input-number #inputNumberView [(ngModel)]="rangeStartValue"
                            (ngModelChange)="handleRangeStartChange($event)" nzAutoFocus="true" [nzMin]="rangeStart"
                            [nzMax]="rangeStartMaxValue" (keyup.enter)="handleInputEnter()" [nzStep]="rangeStep">
                        </nz-input-number>
                    </span>
                    <span class="range-divider">-</span>
                    <span class="range-end-input range-input">
                        <nz-input-number [(ngModel)]="rangeEndValue" (keyup.enter)="handleInputEnter()"
                            [nzMin]="rangeEndMinValue" [nzMax]="rangeEnd" [nzStep]="rangeStep">
                        </nz-input-number>
                    </span>
                </div>
            </div>
            <div class="filter-options" *ngIf="tableFilters.length > 0 && showFilters === true">
                <div style="width: 100%;display: flex;justify-content: space-between">
                    <div>
                        <i nz-icon nzType="filter" class="filter-icon" nzTheme="fill"></i>
                        <span class="filter-text">检索项：</span>
                    </div>
                    <div>
                        <a (click)="handleClearFilter()">清除</a>
                    </div>
                </div>

                <div class="options-list-wrap">
                    <div class="options-list">
                        <span *ngFor="let opt of tableFilters">
                            <div class="tag" *ngIf="opt.show === true">
                                {{opt['title']}}:
                                <ng-container *ngIf="opt['condition'] === 'equal'; else elseFilterTpl">
                                    {{opt['value']}}
                                </ng-container>
                                <ng-template #elseFilterTpl>
                                    {{opt['value']}} - {{opt['max']}}
                                </ng-template>
                                <i nz-icon nzType="close" (click)="handleTagClose(opt)" nzTheme="outline"></i>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </nz-dropdown-menu>
</div>