import { Component, Input, OnInit } from "@angular/core";
import { NzMessageService } from "ng-zorro-antd";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { MaterialsListService } from "src/app/services/materials-list/materials-list.service";

// 新
interface Item {
  id: number;
  name: string;
  code: string;
  num: number;
}

@Component({
  selector: "app-table-add",
  templateUrl: "./table-add.component.html",
  styleUrls: ["./table-add.component.less"],
})
export class TableAddComponent implements OnInit {
  constructor(
    private Service: MaterialsListService,
    private message: NzMessageService
  ) {}

  ngOnInit(): void {
    this.amendId ? this.getSubpart() : null;

    // 搜索节流
    this.SearchAntiShake();
  }

  // 获取子部件列表
  getSubpart() {
    let [id, page_num, page_size] = [this.amendId, 1, 15];
    this.Service.getMaterialDetailBody(id, page_num, page_size).subscribe(
      (data) => {
        let _table = data.list.map((i): Item => {
          const {
            subComponentId: id,
            subComponentName: name,
            subComponentCode: code,
            subComponentNum: num,
          } = i;
          return { id, name, code, num };
        });
        this.changeTable(_table);
      }
    );
  }

  //#region

  // 表格数据
  table: Array<Item> = [];
  // 编辑缓存
  cacheEdit: { [key: string]: { edit: boolean; data: Item } } = {};

  // 更新编辑缓存
  updateCacheEdit(): void {
    let cache = {};
    this.table.forEach((i) => {
      cache[i.id] = { edit: false, data: { ...i } };
    });
    this.cacheEdit = cache;
  }

  // 修改table
  changeTable(_table: Array<Item>): void {
    this.table = [..._table];
    this.updateCacheEdit();
  }

  @Input() amendId: number;

  // 单个增加子零件
  getOneSubpart(codeString: string) {
    const [code, id, name] = [codeString, "", ""];
    this.Service.getSearchMaterialList(code).subscribe((data) => {
      const { id, name, code } = data.list[0];
      this.table.push({ id, name, code, num: 1 });
      this.changeTable(this.table);
    });
  }

  //  + 号  模态框
  visible = false;
  showModal(): void {
    this.visible = true;
    this.getOptions();
  }

  //  模态框-确认按钮、
  modalOk(): void {
    this.visible = false;
    this.subpartList.forEach((item) => {
      this.getOneSubpart(item);
    });
    this.subpartList = null;
  }

  // 模态框 - 取消
  modalCancel() {
    this.visible = false;
  }

  // 模态框 - 多选-子零件
  subpartList: Array<string> = [];
  // 模态框 - 批量添加
  manyModelChange(values: Array<string>) {
    let list = this.table.map((i) => i.code);
    this.table.forEach((i) => {
      if (values[values.length - 1] == i.code) {
        this.message.info("子零件已存在");
      }
    });
    this.subpartList = values.filter((item) => {
      return list.indexOf(item) == -1;
    });
  }

  // 是否编辑状态 为 true
  editNow: boolean = false;

  // 编辑缓存
  cacheItem: Item;
  // 开始编辑
  startEdit(data: Item): void {
    this.cacheItem = JSON.parse(JSON.stringify(data));
    const { code, id } = data;
    this.getOptions(code);
    this.editNow = true;
    this.updateCacheEdit();
    this.cacheEdit[id].edit = true;
  }

  // 检查编辑项数据 - 是否为空，是否修改了
  editCheck(item: Item): boolean {
    // const item = this.table[index];
    let arr: Array<boolean> = [];
    for (const key in item) {
      arr.push(!!item[key]);
    }

    let isTrue = arr.every((i) => i == true);
    return isTrue;
  }

  // 取消编辑
  editCancel(id: number, index: number): void {
    const { cacheItem } = this;
    // 检查编辑前的数据是否可以恢复
    let bol = this.editCheck(cacheItem);
    if (bol) {
      Object.assign(this.table[index], cacheItem);
      this.changeTable(this.table);
      this.editNow = false;
    } else {
      this.table.splice(index, 1);
      this.changeTable(this.table);
      this.editNow = false;
    }
  }

  // 编辑保存
  editSave(id: number, index: number): void {
    if (this.editCheck(this.table[index])) {
      this.changeTable(this.table);
      this.cacheEdit[id].edit = false;
      this.editNow = false;
      this.cacheItem = null;
    } else {
      this.message.warning("存在未填写完成的子零件信息");
    }
  }

  // 选择框-下拉选项 - 多选
  options: Array<Item> = [];

  // 选择框-是否显示提示
  Tips: boolean = true;

  $searchText: Subject<string> = new Subject<string>();
  SearchAntiShake() {
    this.$searchText
      .pipe(debounceTime(300))
      .pipe(distinctUntilChanged())
      .subscribe((value) => {
        this.getOptionsServer(value);
      });
  }

  getOptionsServer(value: string = "") {
    // 需要做节流
    const [code, wpId, page_size] = ["code", 60, 10];
    this.Service.getSearchMaterialList(value, wpId, page_size).subscribe(
      (data) => {
        let List = data.list.map((i) => {
          const { id, name, code } = i;
          return {
            id,
            name,
            code: code,
            num: 1,
            label: code + `(${name})`,
          };
        });
        this.options = List;
      }
    );
  }

  // 获取服务器的 选择信息options
  getOptions(value: string = "") {
    this.$searchText.next(value);
  }

  // table 的单行 code编码 输入发生变化
  searchChange(value: string, index: number): void {
    if (value) {
      let filterCode = this.table.map((i) => i.code);
      let len = filterCode.filter((i) => i == value).length;

      if (len > 1) {
        // 如果重复了，就不显示
        this.table[index] = { ...this.table[index], code: "", name: "" };
        this.table = [...this.table];
        this.changeTable(this.table);
        this.cacheEdit[this.table[index].id].edit = true;
        this.message.warning("这个子零件已经添加过了");
      } else {
        this.getOptions(value);
        let opt = this.options.filter((i) => {
          return i.code == value;
        });
        const { id, name, code } = opt[0];
        this.table[index] = { id, name, code, num: 1 };
        this.table = [...this.table];
        this.changeTable(this.table);
        this.cacheEdit[id].edit = true;
      }
    }
  }

  // 滚动到底部后
  loadMore() {
    // console.log("到底了");
    // 加载更多 - 这个功能要还是不要呢
  }

  // 一、表格前面的勾选框 start --------------
  // 1.1 是否全选
  selectedAll: boolean = false;
  // 1.2 不确定
  indeterminacy: boolean = false;
  // 1.3 选中的 ID组
  selectedId: { [key: string]: boolean } = {};

  // 1.4 检查所有
  //检查所有选项状态
  checkAll(value: boolean): void {
    this.table.forEach((i) => (this.selectedId[i.id] = value));
    this.refreshStatus();
  }

  // 刷新状态
  refreshStatus(): void {
    const { selectedId, table } = this;
    this.selectedAll = table.every((i) => selectedId[i.id]);
    const { selectedAll } = this;
    this.indeterminacy = table.some((i) => selectedId[i.id]) && !selectedAll;
  }

  // 删除行
  deleteRow(value: Item, i: number) {
    const { name, id } = this.table[i];
    this.message.success(`成功删除:${name}`);
    this.table.splice(i, 1);
    this.changeTable(this.table);
    delete this.selectedId[id];
    this.refreshStatus();
    if (this.table.length == 0) {
      this.selectedAll = false;
    }
  }

  // 表格增加添加行
  addrow() {
    let len = this.table.filter((i) => i.id == 0).length;
    if (len) {
      this.message.warning("填写子零件信息后才能继续添加");
    } else {
      this.editNow = true;
      this.table = [...this.table, { id: 0, name: "", code: "", num: 1 }];
      this.updateCacheEdit();
      this.cacheEdit[0].edit = true;
      this.getOptions();
      this.refreshStatus();
      this.cacheItem = { id: 0, name: "", code: "", num: 1 };
    }
  }

  // 批量删除
  deleteMoreRow() {
    const { selectedId } = this;
    // 选中列表的ID
    let ArrayId: Array<number> = [];
    for (const key in selectedId) {
      if (selectedId[key]) {
        ArrayId.push(+key);
      }
    }
    if (ArrayId.length) {
      let { table } = this;
      table = table.filter((i: Item) => {
        return !ArrayId.includes(i.id);
      });
      this.changeTable(table);
      this.refreshStatus();
      this.selectedAll = false;
      this.selectedId = {};
      this.message.warning("本地删除,保存之后才算生效");
    } else {
      this.message.info("没有选中数据");
    }
  }
  //#endregion
}
