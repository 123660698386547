<div class="page-wrapper">
  <div class="tb-filter-container">
    <div class="tb-date-filter-tab">
      <div class="track-content-container">
        <div
          class="track-content-box"
          [ngClass]="{ disabled: dateRangeDisabled }"
          (click)="moveTabTrack(0)"
        >
          今天
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: dateRangeDisabled }"
          (click)="moveTabTrack(1)"
        >
          本周
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: dateRangeDisabled }"
          (click)="moveTabTrack(2)"
        >
          本月
        </div>
        <div
          class="track-content-box"
          [ngClass]="{ disabled: dateRangeDisabled }"
          (click)="moveTabTrack(3)"
        >
          全部
        </div>
      </div>
      <div
        class="tab-track"
        [ngClass]="{
          pos0: tabTrackIndex === 0,
          pos1: tabTrackIndex === 1,
          pos2: tabTrackIndex === 2,
          pos3: tabTrackIndex === 3
        }"
      ></div>
    </div>
    <div class="tb-con-filter">
      <app-table-filter
        (handleFilterChange)="printListSearch($event)"
        [initTableFilters]="initTableFilters"
        [headers]="filterOrderHeaders"
      ></app-table-filter>
    </div>
  </div>
  <div class="tb-wrapper">
    <nz-table
      [nzLoading]="printListLoading"
      [nzData]="printListData"
      [nzSize]="'small'"
      [nzShowPagination]="false"
    >
      <thead style="border: 1px solid #e8e8e8">
        <tr>
          <th style="background-color: #ffffff">打印时间</th>
          <th style="background-color: #ffffff">打印人</th>
          <th style="background-color: #ffffff" nzAlign="right">标签数量</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let data of printListData">
          <td>
            <a [routerLink]="['/print', data.id]">{{
              dateFormatFunc("yyyy-MM-dd HH:mm:ss", data.createTime)
            }}</a>
          </td>
          <td>{{ data.createUser }}</td>
          <td nzAlign="right">{{ data.total }}</td>
        </tr>
      </tbody>
    </nz-table>
    <div style="height: 20px"></div>
    <app-table-page
      [total]="printListMap.total"
      [pageSizeOptions]="pageSizeOptions"
      [current]="printListPostParams.page"
      [pageSize]="printListPostParams.size"
      (handlePageIndexChange)="handlePrintListPageIndexChange($event)"
      (handlePageSizeChange)="handlePrintListPageSizeChange($event)"
    >
    </app-table-page>
  </div>
</div>
