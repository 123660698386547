import { Component, Input, OnInit } from "@angular/core";
import { RetrospectStatementService } from "src/app/services/retrospect-statement/retrospect-statement.service";

interface Arr {
  code: string;
  date: string;
  batch: string;
}

interface PartType {
  id: string;
  expand: boolean;
  subComponentCode: string;
  subComponentName: string;
  actualNum: number;
  num: number;
  // description: Array<string>;
  description: Array<Arr>;
}

@Component({
  selector: "app-retrospect-table",
  templateUrl: "./retrospect-table.component.html",
  styleUrls: ["./retrospect-table.component.less"],
})
export class RetrospectTableComponent implements OnInit {
  constructor(private retrospectServers: RetrospectStatementService) {}

  mapOfExpandData: { [key: string]: boolean } = {};
  listOfData: Array<PartType> = [];
  @Input() detailID: number;

  // 获取子零件code列表
  getChildrenBarCodelist() {
    this.retrospectServers
      .getRetrospectDetailBody(this.detailID)
      .subscribe((data) => {
        this.listOfData = data.list.map((item): PartType => {
          const { subComponentCode, subComponentName, actualNum, num } = item;
          return {
            id: subComponentCode,
            expand: false,
            subComponentCode: subComponentCode,
            subComponentName: subComponentName,
            actualNum: actualNum,
            num: num,
            description: [],
          };
        });
      });
  }

  HandleExpandChange(row, event) {
    if (event) {
      // let num = "URL传递过来的数组";
      const { subComponentCode } = row;
      this.retrospectServers
        .getDetailedBarcode(this.detailID, subComponentCode)
        .subscribe((data) => {
          this.listOfData.forEach((element) => {
            if (row.id == element.id) {
              element.description = data.list;
            }
          });
        });
    }
  }

  ngOnInit(): void {
    this.getChildrenBarCodelist();
  }
}
