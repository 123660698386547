<div class="viewbg">
  <div style="padding: 5px 20px 20px 20px">
    <span class="span">
      <i nz-icon nzType="form"></i
      ><a style="color: #595959" (click)="handleCompile()">编辑</a></span
    >
    <a [routerLink]="['/scanner/materials']">
      <i
        nz-icon
        nzType="close"
        style="float: right; font-size: 16px"
        nzTheme="outline"
      ></i
    ></a>
  </div>
  <div style="padding-bottom: 20px">
    <ul
      nz-menu
      nzMode="horizontal"
      style="padding: 0px 40px; font-weight: 600; color: black"
    >
      总成零件
    </ul>
    <ul nz-menu style="padding: 40px; padding-bottom: 40px">
      <div nz-row>
        <div nz-col nzSpan="10">
          <span style="font-weight: 600">总成零件编码： </span
          >{{ assembly.code }}
        </div>
        <div nz-col nzSpan="10">
          <span style="font-weight: 600">总成零件名称：</span>
          {{ assembly.name }}
        </div>
      </div>
    </ul>
  </div>

  <div>
    <ul
      nz-menu
      nzMode="horizontal"
      style="padding: 0px 40px; font-weight: 600; color: black"
    >
      子零件信息
    </ul>
    <ul nz-menu style="padding: 10px 30px">
      <app-table
        [selectbox]="false"
        myNzAlign="num"
        [Loading]="ListLoading"
        [listHeader]="listHeader"
        [ListData]="listMaterial"
        nzSize="middle"
      >
        <app-table-page
          name="pagination"
          [total]="Pagination.total"
          [pageSizeOptions]="Pagination.pageSizeOptions"
          [current]="Pagination.pageIndex"
          [pageSize]="Pagination.pageSize"
          (handlePageIndexChange)="PageIndexChange($event)"
          (handlePageSizeChange)="PageSizeChange($event)"
        >
        </app-table-page>
      </app-table>
    </ul>
  </div>
</div>
