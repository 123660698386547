import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ScanService } from "src/app/services/scan/scan.service";
const TABLE_TD_WIDTH = 13.0;
const TABLE_TD_PDDING = 15.0;
const EMPTY_TABLE_TD_WIDTH = 20.0;
@Component({
  selector: "app-scanner-scan-detail",
  templateUrl: "./scanner-scan-detail.component.html",
  styleUrls: ["./scanner-scan-detail.component.less"],
})
export class ScannerScanDetailComponent implements OnInit {
  scanId: string;
  page: number = 1;
  size: number = 10;
  total = 0;
  headers = [];
  data = [];
  tableWidth = "";
  loading = false;
  pageSizeOptions = [10, 15, 20, 30, 40, 50];

  constructor(
    private activatedRoute: ActivatedRoute,
    private service: ScanService
  ) {
    this.activatedRoute.params.subscribe((params) => {
      if (params.id) {
        this.scanId = params.id;
      }
    });
  }

  ngOnInit() {
    this.getDetail();
  }

  getDetail() {
    this.loading = true;
    const insId = window.localStorage.getItem("ins");
    this.service
      .scanDetailGet(insId, this.page, this.size, this.scanId)
      .subscribe(
        (data) => {
          this.loading = false;
          const { list, titles, total } = data;
          this.total = total;
          this.headers = titles.map((item) => {
            return { ...item, id: item.name };
          });
          this.adjustTbFixedHeader();
          if (list.length == 0) return;
          list.forEach((item: any, index: number) => {
            let itemValue: { [key: string]: any } = {};
            itemValue["lenMap"] = {};
            itemValue["key"] = `${index}`;
            let len = 0;
            for (let j = 0; j < this.headers.length; j++) {
              let header = this.headers[j];
              let value: any;
              if (item[header.id] === undefined) {
                len += header.label.length * TABLE_TD_WIDTH + TABLE_TD_PDDING;
                itemValue["lenMap"][j.toString()] =
                  header.label.length * TABLE_TD_WIDTH + TABLE_TD_PDDING;
                itemValue[header.id] = null;
              } else {
                value = item[header.id];
                if (!value) {
                  const currentLen =
                    header.label.length * TABLE_TD_WIDTH + TABLE_TD_PDDING;
                  if (index - 1 < 0) {
                    len += currentLen;
                    itemValue["lenMap"][j.toString()] = currentLen;
                  } else {
                    const prevLen =
                      this.data[index - 1]["lenMap"][j.toString()];
                    len += currentLen > prevLen ? currentLen : prevLen;
                    itemValue["lenMap"][j.toString()] =
                      currentLen > prevLen ? currentLen : prevLen;
                  }
                  itemValue[header.id] = item[header.id];
                } else {
                  const currentLen =
                    value.length > header.label.length
                      ? value.length * TABLE_TD_WIDTH + TABLE_TD_PDDING
                      : header.label.length * TABLE_TD_WIDTH + TABLE_TD_PDDING;
                  if (index - 1 < 0) {
                    itemValue["lenMap"][j.toString()] = currentLen;
                    len += currentLen;
                  } else {
                    const prevLen =
                      this.data[index - 1]["lenMap"][j.toString()];
                    itemValue["lenMap"][j.toString()] =
                      currentLen > prevLen ? currentLen : prevLen;
                    len += currentLen > prevLen ? currentLen : prevLen;
                  }
                  itemValue[header.id] = item[header.id];
                }
              }
            }
            this.data.push(itemValue);
          });
          this.data = [...this.data];
          const lenMap = this.data[this.data.length - 1]["lenMap"];
          let res = 0;
          this.headers = this.headers.map((item: any, i: number) => {
            res += lenMap[i] + 45;
            return {
              ...item,
              len: `${lenMap[i] + 45}px`,
            };
          });
          this.tableWidth = `${res + 50}px`;
        },
        (err) => {
          this.loading = false;
        }
      );
  }

  adjustTbFixedHeader() {
    let len = 0;
    for (let j = 0; j < this.headers.length; j++) {
      const data = this.headers[j];
      len += data.label.length * EMPTY_TABLE_TD_WIDTH + TABLE_TD_PDDING;
    }
    this.tableWidth = `${len + 200}px`;
  }

  handlePageIndexChange(page: number) {
    this.page = page;
    this.getDetail();
  }

  handlePageSizeChange(size: number) {
    this.page = 1;
    this.size = size;
    this.getDetail();
  }
}
