import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { MaterialsListService } from "src/app/services/materials-list/materials-list.service";
// 处理过滤条件的标头
// import { List, ListHeader } from "./headers";

@Component({
  selector: "app-material-details",
  templateUrl: "./material-details.component.html",
  styleUrls: ["./material-details.component.less"],
})
export class MaterialDetailsComponent implements OnInit {
  constructor(
    private router: Router,
    private materialsService: MaterialsListService,
    private activateInfo: ActivatedRoute
  ) {}

  detailID: number;
  ngOnInit() {
    this.activateInfo.paramMap.subscribe((queryParams: any) => {
      this.detailID = queryParams.params.id;
    });

    this.getMaterialDetailsListHeader();
    this.getMaterialDetailsList();
  }

  // 编辑
  handleCompile() {
    this.router.navigate(["/materials/addend", { id: this.detailID }]);
  }
  // #endregion

  assembly: {
    code: string;
    name: string;
  } = { code: "", name: "" };

  component_id: number;
  page_num: number = 1;
  page_size: number = 10;

  // 获取总成零件
  getMaterialDetailsListHeader() {
    this.materialsService
      .getMaterialDetailHeader(this.detailID)
      .subscribe((data) => {
        const { componentCode: code, componentName: name } = data.list[0];
        this.assembly = { code, name };
      });
  }

  // 获取子零件信息
  getMaterialDetailsList() {
    const { detailID, page_num, page_size } = this;
    this.materialsService
      .getMaterialDetailBody(detailID, page_num, page_size)
      .subscribe((data) => {
        this.Pagination.total = data.total;
        this.listMaterial = data.list.map((item) => {
          const {
            subComponentId,
            subComponentName,
            subComponentCode,
            subComponentNum,
          } = item;
          return {
            id: subComponentId,
            code: subComponentCode,
            name: subComponentName,
            num: subComponentNum,
          };
        });
      });
  }

  //#region  表格
  ListLoading: false;
  listHeader = {
    id: "ID",
    code: "编码",
    names: "名称",
    num: "数量",
  };
  listMaterial = []; //用料清单 - list

  //#endregion

  //#region  Pagination搜索翻页 -- app-table-page
  Pagination = {
    total: 100,
    pageIndex: 1, //当前页数
    pageSize: 10, //每页条数
    pageSizeOptions: [10, 20, 50, 100],
  };

  // 列表页码发生改变
  PageIndexChange(v) {
    this.page_num = v;
    this.getMaterialDetailsList();
  }
  // 列表Size发生改变
  PageSizeChange(v) {
    this.page_size = v;
    this.getMaterialDetailsList();
  }

  //#endregion
}
