import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { RetrospectStatementService } from "src/app/services/retrospect-statement/retrospect-statement.service";
import { environment } from "src/environments/environment";

interface FinishedProduct {
  actualNum: number;
  barcodeNum: number;
  batch: string;
  componentBarCode: string;
  componentCode: string;
  componentName: string;
  createAt: number;
  customField: string;
  customerAddress: string;
  customerPartNum: string;
  date: number;
  days: number;
  englishDescription: string;
  id: number;
  num: number;
  other: string;
  packageNum: number;
  partStatus: string;
  partVersion: string;
  po: string;
  projectNum: string;
  subComponentBarCode: string;
  subComponentCode: string;
  subComponentName: string;
  submitAt: number;
  submitUser: string;
  sufBoxCode: string;
  supplierCode: string;
  updateAt: number;
}

@Component({
  selector: "app-retrospect-details",
  templateUrl: "./retrospect-details.component.html",
  styleUrls: ["./retrospect-details.component.less"],
})
export class RetrospectDetailsComponent implements OnInit {
  constructor(
    private retrospectServers: RetrospectStatementService,
    private activateInfo: ActivatedRoute,
    private router: Router
  ) {}

  detailID: number;

  ngOnInit() {
    this.activateInfo.paramMap.subscribe((queryParams: any) => {
      this.detailID = queryParams.params.id;
    });
    this.getFinishedProductDetails();
  }

  FinishedProduct: FinishedProduct;

  // 总成零件详细
  getFinishedProductDetails() {
    this.retrospectServers
      .getRetrospectDetailHeader(this.detailID)
      .subscribe((data) => {
        this.FinishedProduct = data;
      });
  }

  // 导出详情
  handleExport() {
    this.retrospectServers.exportReportId(this.detailID).subscribe((data) => {
      const { batchId } = data;
      this.downloadLink = `${location.origin}/${environment.API}/table/file?batch_id=${batchId}`;
      setTimeout(() => {
        document.getElementById("clickme").click();
      }, 1000);
    });
  }

  downloadLink = "";
}
