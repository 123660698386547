<ul
  nz-menu
  nzMode="horizontal"
  style="padding: 0px 40px; font-weight: 700; color: black"
>
  子零件信息
  <span style="float: right; font-size: 16px">
    <nz-modal
      [(nzVisible)]="visible"
      nzTitle="新增子零件"
      (nzOnCancel)="modalCancel()"
      (nzOnOk)="modalOk()"
    >
      <nz-select
        nzMode="multiple"
        [(ngModel)]="subpartList"
        nzPlaceHolder="点击选择子零件(可多选)"
        nzAllowClear
        nzShowSearch
        [nzServerSearch]="true"
        (nzOnSearch)="getOptions($event)"
        (nzScrollToBottom)="loadMore()"
        (ngModelChange)="manyModelChange($event)"
      >
        <nz-option *ngIf="subpartList" nzDisabled nzCustomContent>
          可键入关键字进行搜索
        </nz-option>
        <nz-option
          *ngFor="let o of options"
          [nzValue]="o.code"
          [nzLabel]="o.label"
        ></nz-option>
      </nz-select>
    </nz-modal>
    <button
      nz-button
      nz-popover
      nzType="link"
      nzPopoverContent="新增"
      [disabled]="editNow"
      (click)="showModal()"
    >
      <svg
        t="1629203731739"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="60553"
        width="16"
        height="16"
      >
        <path
          d="M892.95285 447.568549H576.43094V131.045615c0-35.815717-29.03529-64.849984-64.847937-64.849984-35.81674 0-64.851007 29.034267-64.851007 64.849984v316.522934H130.208039c-35.815717 0-64.849984 29.036313-64.849984 64.849983s29.034267 64.84896 64.849984 64.848961h316.523957v316.522933c0 35.81367 29.034267 64.849984 64.851007 64.849984 35.812647 0 64.847937-29.036313 64.847937-64.849984V577.266469h316.52191c35.815717 0 64.851007-29.034267 64.851007-64.84896s-29.03529-64.84896-64.851007-64.84896z"
          p-id="60554"
          fill="#5B6C74"
        ></path>
      </svg>
    </button>
    <button
      nz-button
      nz-popover
      nzPopoverContent="删除"
      nz-popconfirm
      nzPopconfirmTitle
      nzType="link"
      nzPopconfirmTitle="确定删除吗?"
      [disabled]="editNow"
      (nzOnConfirm)="deleteMoreRow()"
    >
      <svg
        t="1625456815975"
        class="icon"
        viewBox="0 0 1024 1024"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        p-id="8880"
        width="16"
        height="16"
      >
        <path
          d="M799.2 874.4c0 34.4-28 62.4-62.368 62.4H287.2a62.496 62.496 0 0 1-62.4-62.4V212h574.4v662.4zM349.6 100c0-7.2 5.6-12.8 12.8-12.8h300c7.2 0 12.768 5.6 12.768 12.8v37.6H349.6V100z m636.8 37.6H749.6V100c0-48-39.2-87.2-87.2-87.2h-300a87.392 87.392 0 0 0-87.2 87.2v37.6H37.6C16.8 137.6 0 154.4 0 175.2s16.8 37.6 37.6 37.6h112v661.6A137.6 137.6 0 0 0 287.2 1012h449.6a137.6 137.6 0 0 0 137.6-137.6V212h112c20.8 0 37.6-16.8 37.6-37.6s-16.8-36.8-37.6-36.8zM512 824c20.8 0 37.6-16.8 37.6-37.6v-400c0-20.8-16.768-37.6-37.6-37.6-20.8 0-37.6 16.8-37.6 37.6v400c0 20.8 16.8 37.6 37.6 37.6m-175.2 0c20.8 0 37.6-16.8 37.6-37.6v-400c0-20.8-16.8-37.6-37.6-37.6s-37.6 16.8-37.6 37.6v400c0.8 20.8 17.6 37.6 37.6 37.6m350.4 0c20.8 0 37.632-16.8 37.632-37.6v-400c0-20.8-16.8-37.6-37.632-37.6-20.768 0-37.6 16.8-37.6 37.6v400c0 20.8 16.8 37.6 37.6 37.6"
          p-id="8881"
          fill="#5B6C74"
        ></path>
      </svg>
    </button>
  </span>
</ul>
<ul nz-menu style="padding: 25px">
  <nz-table
    #TableEdit
    nzBordered
    [nzData]="table"
    [nzShowPagination]="false"
    [nzFrontPagination]="false"
    nzSize="middle"
  >
    <thead>
      <tr>
        <th
          nzShowCheckbox
          [(nzChecked)]="selectedAll"
          [nzIndeterminate]="indeterminacy"
          (nzCheckedChange)="checkAll($event)"
        ></th>
        <th nzWidth="40%">编码</th>
        <th nzWidth="30%">名称</th>
        <th nzWidth="8%">数量</th>
        <th nzAlign="right">操作</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of TableEdit.data; let i = index">
        <td
          style="width: 50px"
          nzShowCheckbox
          [(nzChecked)]="selectedId[data.id]"
          (nzCheckedChange)="refreshStatus()"
        ></td>
        <td>
          <ng-container *ngIf="!cacheEdit[data.id].edit; else nameInputTpl">
            {{ data.code }}({{ data.name }})
          </ng-container>
          <ng-template #nameInputTpl>
            <!-- <input nz-input [(ngModel)]="data.code" /> -->
            <nz-select
              nzMode="default"
              [(ngModel)]="data.code"
              nzPlaceHolder="选择或搜索更多"
              nzAllowClear
              nzShowSearch
              [nzServerSearch]="true"
              (nzOnSearch)="getOptions($event)"
              (nzScrollToBottom)="loadMore()"
              (ngModelChange)="searchChange($event, i)"
            >
              <nz-option *ngIf="Tips" nzDisabled nzCustomContent>
                可键入关键字进行搜索
              </nz-option>
              <nz-option
                *ngFor="let o of options"
                [nzValue]="o.code"
                [nzLabel]="o.label"
              ></nz-option>
            </nz-select>
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="!cacheEdit[data.id].edit; else ageInputTpl">
            {{ data.name }}
          </ng-container>
          <ng-template #ageInputTpl>
            <input nz-input [(ngModel)]="data.name" [disabled]="true" />
          </ng-template>
        </td>
        <td>
          <ng-container *ngIf="!cacheEdit[data.id].edit; else addressInputTpl">
            {{ data.num }}
          </ng-container>
          <ng-template #addressInputTpl>
            <nz-input-number
              [(ngModel)]="data.num"
              [nzMin]="1"
              [nzMax]="10000"
              [nzStep]="1"
            ></nz-input-number>
          </ng-template>
        </td>
        <td nzAlign="right">
          <div class="editable-row-operations">
            <ng-container *ngIf="!cacheEdit[data.id].edit; else saveTpl">
              <!-- <a (click)="editstart(data.id)">编辑</a> -->
              <button
                nz-button
                nzType="link"
                [disabled]="editNow"
                (click)="startEdit(data)"
              >
                编辑
              </button>

              <!-- <a
                nz-popconfirm
                nzTitle="确定删除吗?"
                (nzOnConfirm)="deleteRow(data, i)"
                >删除</a
              > -->
              <button
                nz-button
                nzType="link"
                nz-popconfirm
                nzTitle="确定删除吗?"
                [disabled]="editNow"
                (nzOnConfirm)="deleteRow(data, i)"
              >
                删除
              </button>
            </ng-container>
            <ng-template #saveTpl>
              <!-- <a (click)="editSave(data.id)">保存</a>
              <a (click)="editCancel(data.id, i)">取消</a> -->
              <button
                nz-button
                nzType="link"
                [disabled]="!editNow"
                (click)="editSave(data.id, i)"
                style="border: 0; background: transparent"
              >
                保存
              </button>
              <button
                nz-button
                nzType="link"
                [disabled]="!editNow"
                (click)="editCancel(data.id, i)"
                style="border: 0; background: transparent"
              >
                取消
              </button>
            </ng-template>
          </div>
        </td>
      </tr>
    </tbody>
  </nz-table>
  <button
    nz-button
    nzType="dashed"
    nzBlock
    style="margin-top: 20px"
    [disabled]="editNow"
    (click)="addrow()"
  >
    + 添加一行子零件
  </button>
</ul>
