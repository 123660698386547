import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor(private http: HttpClient) {}

  // 获取打印列表
  printListGet(
    instanceId: string,
    params: {
      page: number;
      size: number;
      createTimeStart: number;
      createTimeEnd: number;
      totalStart: number;
      totalEnd: number;
      createUser: string;
    }
  ) {
    return this.http
      .post<{
        total: number;
        list: Array<{
          id: number;
          createTime: number;
          createUser: string;
          total: number;
        }>;
      }>(
        `/${environment.API}/print/list`,
        { ...params },
        {
          headers: {
            instanceId,
          },
        }
      )
      .pipe(map((res) => res));
  }

  // 打印详情数据
  printDetailGet(
    instanceId: string,
    page: number,
    size: number,
    printId: string
  ) {
    return this.http
      .get<{
        total: number;
        list: Array<{ [key: string]: any }>;
        titles: Array<{
          name: string;
          label: string;
        }>;
      }>(
        `/${environment.API}/print/detail?page=${page}&size=${size}&printId=${printId}`,
        {
          headers: {
            instanceId,
          },
        }
      )
      .pipe(map((res) => res));
  }
}
